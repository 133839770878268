export const moduleLegacy = {
    namespaced: true,
    state () {
        return {
            lastDistribution: null,
            rewardsTimer: '00:00:00',
            legacyTimer: null,
            progressDone: 0,
            nft: [
                {
                    nftType: 'Silver',
                    cwrUpperLimit: '2.50',
                    referralRewardsLevel: '3',
                    daoVote: '1',
                    price: '1000',
                    minted: '163 / 3000',
                    nftName: 'silver',
                    index: 0,
                    level: 1,
                    progress: '0.25',
                    vid: 'https://app.arkfi.io/vid/silver.mp4'
                },
                {
                    nftType: 'Gold',
                    cwrUpperLimit: '6.00',
                    referralRewardsLevel: '9',
                    daoVote: '4',
                    price: '4000',
                    minted: '20 / 500',
                    nftName: 'gold',
                    index: 1,
                    level: 2,
                    progress: '0.5',
                    vid: 'https://app.arkfi.io/vid/gold.mp4'
                },
                {
                    nftType: 'Platinum',
                    cwrUpperLimit: '13.00',
                    referralRewardsLevel: '15',
                    daoVote: '10',
                    price: '10000',
                    minted: '11 / 100',
                    nftName: 'platinum',
                    index: 2,
                    level: 3,
                    progress: '1',
                    vid: 'https://app.arkfi.io/vid/platinum.mp4'
                }
            ],
            filteredNft: {},
            showFilteredNftModal: false,
            showRewardsModal: false
        }
    },
    getters: {
        showFilteredNftModal(state) {
            return state.showFilteredNftModal
        },
        lastDistribution(state) {
            return state.lastDistribution
        },
        rewardsTimer(state) {
            return state.rewardsTimer
        },
        progressDone(state) {
            return state.progressDone
        },
        nft(state) {
            return state.nft
        },
        filteredNft(state) {
            return state.filteredNft
        },
        showRewardsModal(state) {
            return state.showRewardsModal
        }
    },
    mutations: {
        setLastDistribution(state, val) {
            state.lastDistribution = val
        },
        clearRewardsTimer(state) {
            clearInterval(state.legacyTimer);
        },
        setFilteredNft(state, val) {
            state.filteredNft = val
        },
        setShowFilteredNftModal(state, val) {
            state.showFilteredNftModal = val
        },
        setShowRewardsModal(state, val) {
            state.showRewardsModal = val
        },
        getTimer(state) {
            if (state.lastDistribution !== null) {
                var now = new Date()
                var dateTo = state.lastDistribution.getTime() + (60 * 60 * 24 * 1000)
                var difference = dateTo - now.getTime();
                if (difference <= 0) {
                    state.rewardsTimer = '00:00:00'
                    clearInterval(state.legacyTimer);
                    return;
                } else {
                    var seconds = Math.floor(difference / 1000);
                    var minutes = Math.floor(seconds / 60);
                    var hours = Math.floor(minutes / 60);
                    hours %= 24;
                    minutes %= 60;
                    seconds %= 60;
                    var hourText = hours < 10 ? "0" + hours : hours
                    var minutesText = minutes < 10 ? "0" + minutes : minutes
                    var secondsText = seconds < 10 ? "0" + seconds : seconds
                    state.rewardsTimer = hourText + ":" + minutesText + ":" + secondsText
                }
            }
        },
        setProgressBar(state) {
            if (state.lastDistribution !== null) {
                var now = new Date()
                var dateTo = state.lastDistribution.getTime() + (60 * 60 * 24 * 1000) // total
                var percPerSecond = 100 / (dateTo - state.lastDistribution.getTime())
                var difference = now.getTime() - dateTo
                var done = 200 + (percPerSecond * difference)
                state.progressDone = done/100
            }
        },
        setNftMinted(state, {level, minted}) {
            state.nft[level-1].minted = minted
        },
        setNftPrice(state, {level, price}) {
            state.nft[level-1].price = price
        }
    },
    actions: {
        setRewardsTimer({ state, commit }) {
            commit('clearRewardsTimer')
            state.vaultTimer = setInterval(() => {
                commit('getTimer')
                commit('setProgressBar')
            }, 1000);
        }
    }
}