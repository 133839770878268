import { legacy } from './contract/index'
import { busd } from '../busd/contract/index'
export const moduleWeb3Legacy = {
    namespaced: true,
    state () {
        return {
            legacySpecs: legacy,
            busdSpecs: busd,
            contractBscLegacy: null,
            contractLegacy: null,
            nftLevel: null,
            nftId: null,
            totalNftSupply: 0,
            nftRewardsPool: 0,
            approvedLegacySilver: null,
            approvedLegacyGold: null,
            approvedLegacyPlatinum: null,
            priceLevelUp: 0,
            shares: 0,
            totalShares: 0,
            claimableRewards: 0,
            nftLocked: true,
            discount: 0,
            nftLevelAccount: 0
        }
    },
    getters: {
        nftLevel(state) {
            return state.nftLevel
        },
        nftId(state) {
            return state.nftId
        },
        totalNftSupply(state) {
            return state.totalNftSupply
        },
        nftRewardsPool(state) {
            return state.nftRewardsPool
        },
        approvedLegacySilver(state) {
            return state.approvedLegacySilver
        },
        approvedLegacyGold(state) {
            return state.approvedLegacyGold
        },
        approvedLegacyPlatinum(state) {
            return state.approvedLegacyPlatinum
        },
        shares(state) {
            return state.shares
        },
        totalShares(state) {
            return state.totalShares
        },
        claimableRewards(state) {
            return state.claimableRewards
        },
        nftLocked(state) {
            return state.nftLocked
        },
        nftLevelAccount(state) {
            return state.nftLevelAccount
        },
        priceLevelUp(state) {
            return state.priceLevelUp
        }
    },
    mutations: {
        Init_Contracts(state, { web3, web3bsc }) {
            state.contractBscLegacy = new web3bsc.eth.Contract(state.legacySpecs.abi, state.legacySpecs.ca)
            state.contractLegacy = new web3.eth.Contract(state.legacySpecs.abi, state.legacySpecs.ca)
        },
        setNftLevel(state, val) {
            state.nftLevel = val
        },
        setNftId(state, val) {
            state.nftId = val
        },
        setTotalNftSupply(state, val) {
            state.totalNftSupply = val
        },
        setNftRewardsPool(state, val) {
            state.nftRewardsPool = val
        },
        setApprovedLegacySilver(state, val) {
            state.approvedLegacySilver = val
        },
        setApprovedLegacyGold(state, val) {
            state.approvedLegacyGold = val
        },
        setApprovedLegacyPlatinum(state, val) {
            state.approvedLegacyPlatinum = val
        },
        setShares(state, val) {
            state.shares = val
        },
        setTotalShares(state, val) {
            state.totalShares = val
        },
        setClaimableRewards(state, val) {
            state.claimableRewards = val
        },
        setNftLocked(state, val) {
            state.nftLocked = val
        },
        setNftLevelAccount(state, val) {
            state.nftLevelAccount = val
        },
        setPriceLevelUp(state, val) {
            state.priceLevelUp = val
        }
    },
    actions: {
        async GetLastDistribution_Legacy({ state }) {
            try {
                var _val = await state.contractBscLegacy.methods.lastDistribution().call();
                _val = new Date(Number(_val) * 1000)
                return _val;
            }
            catch {
                return new Date();
            }
        },
        async IsLocked_Legacy({ state }, { id }) {
            try {
                var _val = await state.contractBscLegacy.methods.locked(id).call();
                return _val;
            }
            catch {
                return true;
            }
        },
        async GetRewardsPool_Legacy({ state }, { web3 }) {
            try {
                var _val = await state.contractBscLegacy.methods.rewardsPool().call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(0);
            }
            catch {
                return true;
            }
        },       
        async GetPriceSilver_Legacy({ state }, { web3 }) {
            try {
                var _val = await state.contractBscLegacy.methods.nftPriceOfLevel(1).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val)
            }
            catch {
                return 1000;
            }
        },       
        async GetPriceGold_Legacy({ state }, { web3 }) {
            try {
                var _val = await state.contractBscLegacy.methods.nftPriceOfLevel(2).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val)
            }
            catch {
                return 4000;
            }
        },
        async GetPricePlatinum_Legacy({ state }, { web3 }) {
            try {
                var _val = await state.contractBscLegacy.methods.nftPriceOfLevel(3).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val)
            }
            catch {
                return 10000;
            }
        },
        async GetLevelOfAccount_Legacy({ state }, { address }) {
            try {
                var _val = await state.contractBscLegacy.methods.getLevels(address).call();
                return Number(_val)
            }
            catch {
                return 0;
            }
        },
        async GetLevelNFT_Legacy({ state }, { nftId }) {
            try {
                var _val = await state.contractBscLegacy.methods.levelOfNft(nftId).call();
                return Number(_val)
            }
            catch {
                return 0;
            }
        },
        async GetPriceForLevelUp_Legacy({ state }, { web3, nftId }) {
            try {
                var _val = await state.contractBscLegacy.methods.getPriceForLevelUp(nftId).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val)
            }
            catch {
                return 0;
            }
        },
        async GetShares_Legacy({ state }, { web3, address }) {
            try {
                var _val = await state.contractBscLegacy.methods.getShares(address).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val)
            }
            catch {
                return 0;
            }
        },
        async GetTotalShares_Legacy({ state }, { web3 }) {
            try {
                var _val = await state.contractBscLegacy.methods.totalShares().call();
                _val = web3.utils.fromWei(_val);
                return Number(_val)
            }
            catch {
                return 0;
            }
        },
        async GetNFTOfOwner_Legacy({ state }, { address }) {
            try {
                var _val = await state.contractBscLegacy.methods.tokenOfOwnerByIndex(address, 0).call();
                return _val;
            }
            catch {
                return "";
            }
        },
        async GetTotalSupply_Legacy({ state }) {
            try {
                var _val = await state.contractBscLegacy.methods.totalSupply().call();
                return _val;
            }
            catch {
                return "3600";
            }
        },
        async GetTotalSupplyOfLevel_Legacy({ state }, { level }) {
            try {
                var _val = await state.contractBscLegacy.methods.totalSupplyOfLevel(level).call();
                return _val;
            }
            catch {
                return 0;
            }
        },
        async GetClaimableRewards_Legacy({ state }, { web3, address }) {
            try {
                var _val = await state.contractBscLegacy.methods.getClaimableRewards(address).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        // interactie met wallet gebruiker
        async Mint_Legacy({ state }, { level, address }) {
            var _res = null
            // the time-out catch
            const timeOut = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        show: true,
                        msg: "We didn't receive the transaction confirmation within a reasonable time. Please check your wallet if your request came through",
                        variant: 'warning'
                    })
                }, 25000)
            })
            // the user action
            const userAction = new Promise((resolve, reject) => {
                state.contractLegacy.methods.mint(level).send({
                    from: address
                }).on("receipt", () => {
                    resolve({
                        show: true,
                        msg: "You've succesfully minted your NFT",
                        variant: 'success'
                    })
                }).on("error", (error) => {
                    reject({
                        show: true,
                        msg: error.message,
                        variant: 'danger'
                    })
                })
            })
            // do the request
            await Promise.race([userAction, timeOut])
            .then((res) => {
                _res = res
            })
            .catch((err) => {
                _res = err
            })
            return _res
        },
        async LevelUp_Legacy({ state }, { id, address }) {
            var _res = null
            // the time-out catch
            const timeOut = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        show: true,
                        msg: "We didn't receive the transaction confirmation within a reasonable time. Please check your wallet if your request came through",
                        variant: 'warning'
                    })
                }, 25000)
            })
            // the user action
            const userAction = new Promise((resolve, reject) => {
                state.contractLegacy.methods.levelUp(id).send({
                    from: address
                }).on("receipt", () => {
                    resolve({
                        show: true,
                        msg: "You've succesfully upgraded your NFT",
                        variant: 'success'
                    })
                }).on("error", (error) => {
                    reject({
                        show: true,
                        msg: error.message,
                        variant: 'danger'
                    })
                })
            })
            // do the request
            await Promise.race([userAction, timeOut])
            .then((res) => {
                _res = res
            })
            .catch((err) => {
                _res = err
            })
            return _res
        },
        async LockNft_Legacy({ state }, { address }) {
            var _res = null
            // the time-out catch
            const timeOut = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        show: true,
                        msg: "We didn't receive the transaction confirmation within a reasonable time. Please check your wallet if your request came through",
                        variant: 'warning'
                    })
                }, 25000)
            })
            // the user action
            const userAction = new Promise((resolve, reject) => {
                state.contractLegacy.methods.lockNft().send({
                    from: address
                }).on("receipt", () => {
                    resolve({
                        show: true,
                        msg: "You've locked your NFT",
                        variant: 'success'
                    })
                }).on("error", (error) => {
                    reject({
                        show: true,
                        msg: error.message,
                        variant: 'danger'
                    })
                })
            })
            // do the request
            await Promise.race([userAction, timeOut])
            .then((res) => {
                _res = res
            })
            .catch((err) => {
                _res = err
            })
            return _res
        }
    }
}