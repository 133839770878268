import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { store } from './store';
import { mixins } from './mixins';

import { IonicVue } from '@ionic/vue';
import VueQrcodeReader from "vue3-qrcode-reader";

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/style.css';

/* Highcharts */
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more'

highchartsMore(Highcharts)

/* PWA Elements */
import { defineCustomElements } from '@ionic/pwa-elements/loader';

defineCustomElements(window);

const app = createApp(App)
.use(IonicVue, {
  swipeBackEnabled: false,
  rippleEffect: false
  //mode: 'ios'
})
.use(router)
.use(HighchartsVue)
.use(store)
.use(VueQrcodeReader)
.mixin(mixins)

router.isReady().then(() => {
  app.mount('#app');
});