<template>
    <ion-modal :is-open="scannerModalOpen" :initial-breakpoint="1" :breakpoints="[0, 1]" :handle="true" :show-backdrop="false" :can-dismiss="true" @didDismiss="dismissScannerModal()" style="--border-radius: 27px">
        <scanner-modal-page-base>
            <ion-grid>
                <ion-row>
                    <ion-col class="ion-text-left">
                        <div style="font-size: 20px; color: white; font-weight: 600;" class="ion-margin-top">Scan QR Code</div>
                    </ion-col>
                    <ion-col class="ion-text-right">
                        <ion-button fill="clear" @click="dismissScannerModal()" color="light" style="margin-top: 10px">
                            <ion-icon slot="start" :icon="closeOutline"></ion-icon>
                            Close
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <div class="ion-text-center stream">
                <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit" :track="selected.value">
                    <div style="color: red;" class="frame"></div>
                </qrcode-stream>
            </div>
        </scanner-modal-page-base>
    </ion-modal>
</template>
<script>
    import { 
        IonModal, IonGrid, IonRow, IonCol, IonButton, alertController, IonIcon
    } from '@ionic/vue'
    import {
        defineComponent, ref
    } from 'vue'
    import {
        closeOutline
    } from 'ionicons/icons';
    import ScannerModalPageBase from '@/components/main/scanner/ScannerModalPageBase.vue'
    export default defineComponent({
        name: 'ScannerModal',
        data () {
            const err = ref('')
            const scanResult = ref('')
            const options = [
                { text: "nothing (default)", value: undefined },
                { text: "outline", value: this.paintOutline },
                { text: "centered text", value: this.paintCenterText },
                { text: "bounding box", value: this.paintBoundingBox },
            ]
            const selected = options[0]
            const camera = 'auto'
            return {
                err,
                options,
                selected,
                scanResult,
                camera,
                closeOutline
            }
        },
        methods: {
            dismissScannerModal() {
                this.$store.commit('moduleMain/setScannerModalOpen', false)
            },
            async onDecode(result) {
                // so we need to fetch only the address from the url
                var _hostLength = this.host.length
                var _length = _hostLength + 17
                var _val = result.substring(_length,result.length)
                // OK, go on now
                const alert = await alertController.create({
                    header: 'Scan result',
                    subHeader: "The scanned referral code is: " + _val.substring(0,6) + '..' + _val.slice(-6),
                    buttons: [{ text: 'Retry', role: 'cancel', cssClass: 'alert-button-custom' }, { text: 'Use', role: 'confirm', cssClass: 'alert-button-custom' }],
                    cssClass: 'custom-alert'
                })
                await alert.present()
                const { role } = await alert.onDidDismiss()
                if (role === 'confirm') {
                    if (this.scanFromView !== '') {
                        if (this.scanFromView === 'buyArk') {
                            this.$store.commit('moduleHome/setBuyReferrer', _val)
                        }
                        if (this.scanFromView === 'depositArk') {
                            this.$store.commit('moduleVault/setDepositReferrer', _val)
                        }
                        this.$store.commit('moduleMain/setScannerModalOpen', false)
                    }
                }
                this.pause()
                await this.timeout(250)
                this.unpause()
            },
            unpause () {
                this.camera = 'auto'
            },
            pause () {
                this.camera = 'off'
            },
            timeout (ms) {
                return new Promise(resolve => {
                    window.setTimeout(resolve, ms)
                })
            },
            paintOutline (detectedCodes, ctx) {
                for (const detectedCode of detectedCodes) {
                    const [ firstPoint, ...otherPoints ] = detectedCode.cornerPoints

                    ctx.strokeStyle = "red";

                    ctx.beginPath();
                    ctx.moveTo(firstPoint.x, firstPoint.y);
                    for (const { x, y } of otherPoints) {
                        ctx.lineTo(x, y);
                    }
                    ctx.lineTo(firstPoint.x, firstPoint.y);
                    ctx.closePath();
                    ctx.stroke();
                }
            },
            paintBoundingBox (detectedCodes, ctx) {
                for (const detectedCode of detectedCodes) {
                    const { boundingBox: { x, y, width, height } } = detectedCode

                    ctx.lineWidth = 2
                    ctx.strokeStyle = '#007bff'
                    ctx.strokeRect(x, y, width, height)
                }
            },
            paintCenterText (detectedCodes, ctx) {
                for (const detectedCode of detectedCodes) {
                    const { boundingBox, rawValue } = detectedCode

                    const centerX = boundingBox.x + boundingBox.width/ 2
                    const centerY = boundingBox.y + boundingBox.height/ 2

                    const fontSize = Math.max(12, 50 * boundingBox.width/ctx.canvas.width)
                    console.log(boundingBox.width, ctx.canvas.width)

                    ctx.font = `bold ${fontSize}px sans-serif`
                    ctx.textAlign = "center"

                    ctx.lineWidth = 3
                    ctx.strokeStyle = '#35495e'
                    ctx.strokeText(detectedCode.rawValue, centerX, centerY)

                    ctx.fillStyle = '#5cb984'
                    ctx.fillText(rawValue, centerX, centerY)
                }
            },
            async onInit (promise) {
                try {
                    await promise
                } catch (error) {
                    if (error.name === 'NotAllowedError') {
                        this.err = "ERROR: you need to grant camera access permission"
                    } else if (error.name === 'NotFoundError') {
                        this.err = "ERROR: no camera on this device"
                    } else if (error.name === 'NotSupportedError') {
                        this.err = "ERROR: secure context required (HTTPS, localhost)"
                    } else if (error.name === 'NotReadableError') {
                        this.err = "ERROR: is the camera already in use?"
                    } else if (error.name === 'OverconstrainedError') {
                        this.err = "ERROR: installed cameras are not suitable"
                    } else if (error.name === 'StreamApiNotSupportedError') {
                        this.err = "ERROR: Stream API is not supported in this browser"
                    } else if (error.name === 'InsecureContextError') {
                        this.err = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
                    } else {
                        this.err = `ERROR: Camera error (${error.name})`;
                    }
                }
            }
        },
        components: {
            IonModal,
            ScannerModalPageBase,
            IonGrid,
            IonRow,
            IonCol,
            IonButton,
            IonIcon
        },
        computed: {
            scannerModalOpen: {
                get() {
                    return this.$store.getters['moduleMain/scannerModalOpen']
                }
            },
            scanFromView: {
                get() {
                    return this.$store.getters['moduleMain/scanFromView']
                }
            },
            host: {
                get() {
                    return window.location.hostname
                }
            }
        }
    })
</script>
<style scoped>
    ion-alert.alert-title {
        color: white;
    }
    .stream {
        margin: auto;
        width: 100%;
        height: 95%
    }
    .frame {
        width: 200px;
        height: 200px;
        background:
            linear-gradient(to right, black 4px, transparent 4px) 0 0,
            linear-gradient(to right, black 4px, transparent 4px) 0 100%,
            linear-gradient(to left, black 4px, transparent 4px) 100% 0,
            linear-gradient(to left, black 4px, transparent 4px) 100% 100%,
            linear-gradient(to bottom, black 4px, transparent 4px) 0 0,
            linear-gradient(to bottom, black 4px, transparent 4px) 100% 0,
            linear-gradient(to top, black 4px, transparent 4px) 0 100%,
            linear-gradient(to top, black 4px, transparent 4px) 100% 100%;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        position: absolute;
        top: 0px;
        bottom: 0px;
        right: 0px;
        left: 0px;
        margin: auto;
    }
    ion-button {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
    }
</style>