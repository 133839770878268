import { createRouter, createWebHistory } from '@ionic/vue-router';
import { store } from '../store/index.js'
const routes = [
  {
    path: '/',
    redirect: '/suite/home',
    meta: {
      routeName: 'web',
      needsWC: true,
      statusBarColor: '#1e1f23'
    }
  },
  // route when PWA is visited from a device <> iOS and <> Android
  { path: '/web',
    component: () => import('@/views/web/'),
    meta: {
      routeName: 'web',
      needsWC: false,
      statusBarColor: '#03071c'
    }
  },
  // suite routes (needs WC)
  {
    path: '/suite/',
    redirect: '/suite/home',
    component: () => import('@/views/suite'),
    children: [
      // vault
      {
        path: '/suite/vault',
        component: () => import('@/views/suite/vault/'),
        meta: {
          routeName: 'vault',
          needsWC: true,
          statusBarColor: '#03071c'
        }
      },
      // foundation
      {
        path: '/suite/foundation',
        component: () => import('@/views/suite/foundation/'),
        meta: {
          routeName: 'foundation',
          needsWC: true,
          statusBarColor: '#03071c'
        }
      },
      // home
      {
        path: '/suite/home',
        component: () => import('@/views/suite/home/'),
        meta: {
          routeName: 'home',
          needsWC: true,
          statusBarColor: '#03071c'
        }
      },
      // legacy
      {
        path: '/suite/legacy',
        component: () => import('@/views/suite/legacy/'),
        meta: {
          routeName: 'legacy',
          needsWC: true,
          statusBarColor: '#03071c'
        }
      },
      // menu
      {
        path: '/suite/menu',
        component: () => import('@/views/suite/menu/'),
        meta: {
          routeName: 'menu',
          needsWC: true,
          statusBarColor: '#03071c'
        }
      },
      //menu -> lottery
      {
        path: '/suite/menu/lottery',
        component: () => import('@/views/suite/menu/lottery/'),
        meta: {
          routeName: 'menu',
          needsWC: true,
          statusBarColor: '#03071c'
        }
      },
      //menu -> podcasts
      {
        path: '/suite/menu/podcasts',
        component: () => import('@/views/suite/menu/podcasts/'),
        meta: {
          routeName: 'menu',
          needsWC: true,
          statusBarColor: '#03071c'
        }
      },
      //menu -> syndicate
      {
        path: '/suite/menu/syndicate',
        component: () => import('@/views/suite/menu/syndicate/'),
        meta: {
          routeName: 'menu',
          needsWC: true,
          statusBarColor: '#03071c'
        }
      },
      //menu -> about
      {
        path: '/suite/menu/about',
        component: () => import('@/views/suite/menu/about/'),
        meta: {
          routeName: 'menu',
          needsWC: true,
          statusBarColor: '#03071c'
        }
      },
      //menu -> settings
      {
        path: '/suite/menu/settings',
        component: () => import('@/views/suite/menu/settings/'),
        meta: {
          routeName: 'menu',
          needsWC: true,
          statusBarColor: '#03071c'
        }
      }
    ]
  },
  // connect
  {
    path: '/connect/:ref',
    component: () => import('@/views/connect/'),
    meta: {
      routeName: 'connect',
      needsWC: false,
      statusBarColor: '#03071c'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('moduleMain/setHideForBondAction', false)
  // check if route needs WalletConnect
  if (!store.getters['moduleMain/walletConnected'] && to.meta.needsWC) {
    next({ path: '/connect/no-ref' })
  } else {
    store.commit("moduleMain/setActiveRoute", to.meta.routeName)
    next()
  }
})

export default router
