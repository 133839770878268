<template>
    <ion-modal :is-open="installModalOpen" @didDismiss="closeModal(false)" :handle="false" :can-dismiss="false">
        <install-modal-page-base>
            <!-- are you in Chrome or safari notification -->
            <div class="ion-text-center splash-screen" v-if="!goOn">
                <div class="splash-container ion-text-center">
                    <div class="splash-vertical-center ion-text-center">
                        <div>
                            <ion-text color="light">
                                <div style="font-size: 26px; font-weight: 600;">Hi There!</div>
                            </ion-text>
                            <br><br>
                            <ion-text color="light">
                                <div style="font-size: 16px; font-weight: 400;">
                                    In order to continue you must be visiting this (referral)link in Chrome (when you're on a Android device) or 
                                    iOS Safari (when you're on an iOS device). Other browsers or in-app browsers won't work.
                                    <br><img src="assets/images/install/browsers.png" style="margin: 25px"/><br>
                                    If you're already in one of these two browsers please click 'Continue'. If not: please click 'Copy link' and 
                                    paste this link in one of these two browsers.
                                </div>
                            </ion-text>
                        </div>
                    </div>
                    <ion-grid class="ion-text-center" style="position: absolute; bottom: 10px; width: 100%">
                        <ion-row>
                            <ion-col>
                                <ion-button shape="round" :color="copyText === null ? 'medium' : 'warning'" expand="block" @click="copyReferral()">{{ copyText === null ? 'Copy link' : copyText }}</ion-button>
                            </ion-col>
                            <ion-col>
                                <ion-button shape="round" color="medium" expand="block" @click="setGoOn()">Continue</ion-button>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </div>
            </div>
            <!-- referral iOS process section -->
            <div class="ion-text-center splash-screen" v-if="!installReady && goOn">
                <div class="splash-container ion-text-center">
                    <div class="splash-vertical-center ion-text-center">
                        <div>
                            <ion-text color="light">
                                <div style="font-size: 26px; font-weight: 600;">Congratulations!</div>
                            </ion-text>
                            <img src="assets/images/install/referral.png" style="max-height: 250px; margin-top: 25px; margin-bottom: 25px"/>
                            <ion-text :color="textColor">
                                <div style="font-size: 16px; font-weight: 400;" v-html="msg"></div>
                            </ion-text>
                        </div>
                    </div>
                    <ion-grid class="ion-text-center" style="position: absolute; bottom: 10px; width: 100%">
                        <ion-row>
                            <ion-col>
                                <ion-button shape="round" color="medium" expand="block" @click="connectWallet()">Process</ion-button>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </div>
            </div>
            <!-- install app section -->
            <ion-grid v-if="installReady">
                <ion-row class="ion-margin-top ion-margin-bottom">
                    <ion-col class="ion-text-center">
                        <ion-text color="light">
                            <div style="font-size: 26px; font-weight: 600;">
                                How to install ArkFi
                            </div>
                        </ion-text>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <ion-grid v-if="installReady && deviceInfo.operatingSystem === 'ios'">
                <ion-row>
                    <ion-col>
                        <ion-text color="light">
                            <ion-icon :icon="logoApple" color="light"></ion-icon> iOS:<br><br>
                            1. Click on the arrow button in the middle of the bottom bar in the Safari browser.<br><br>
                            2. After that choose the option 'Add to home screen'.<br><br>
                            3. ArkFi is now installed and available on the home screen of your device.<br><br>
                        </ion-text>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <ion-grid v-if="installReady && deviceInfo.operatingSystem === 'android'">
                <ion-row>
                    <ion-col>
                        <ion-text color="light">
                            <ion-icon :icon="logoAndroid" color="light"></ion-icon> Android:<br><br>
                            1. Tap the menu icon (3 dots in upper right-hand corner).<br><br>
                            2. Then tap Add to Homescreen / Install app.<br><br>
                            3. You'll be able to enter a custom name for the shortcut and then Chrome will add it to your home screen. The app is now installed.<br><br>
                        </ion-text>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <ion-grid v-if="installReady">
                <ion-row>
                    <ion-col>
                        <ion-text color="light">
                            <ion-icon :icon="trash" color="light"></ion-icon> Removing the PWA:<br><br>
                            The PWA can also be removed from your device. Just follow the same routine as you would removing any other app from your device. Enjoy the experience!<br><br>
                        </ion-text>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col class="ion-text-center">
                        <ion-text color="light">
                            <img src="assets/icon/ark_splash_screen.png" style="height: 50px"/><br>
                            Team ArkFi
                        </ion-text>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </install-modal-page-base>
    </ion-modal>
</template>
<script>
import { 
    IonModal, IonGrid, IonRow, IonCol, IonText, IonIcon, IonButton
} from '@ionic/vue';
import {
    defineComponent
} from 'vue';
import {
    logoAndroid, logoApple, trash
} from 'ionicons/icons'
import InstallModalPageBase from '@/components/main/install/InstallModalPageBase.vue'
let WalletConnectProvider = null
let provider = null
import Web3 from "web3";
export default defineComponent({
    name: 'installModal',
    setup() {
        return {
            logoAndroid,
            logoApple,
            trash
        }
    },
    data() {
        return {
            msg: `You got here through referral link. In order to process your referral on iOS devices please click the 'process' button below to connect your wallet.`,
            textColor: 'light',
            copyText: null,
            goOn: false
        }
    },
    components: {
        IonModal,
        InstallModalPageBase,
        IonGrid, 
        IonRow, 
        IonCol, 
        IonText,
        IonIcon,
        IonButton
    },
    methods: {
        closeModal(val) {
            this.$store.commit('moduleMain/triggerInstallModal', val)
        },
        setGoOn() {
            this.goOn = true
        },
        async copyReferral() {
            var _status = 'fail'
            var _copy = `https://${this.host}/connect/${this.referralFromQR}`
            await navigator.clipboard.writeText(_copy).then(() => {
                _status = 'success'
            })
            if (_status === 'success') {
                this.copyText = 'Link copied!'
                setTimeout(() => {
                    this.copyText = null
                }, 1500)
            } else {
                this.copyText = 'Fail..'
                setTimeout(() => {
                    this.copyText = null
                }, 1500)
            }
        },
        async connectWallet() {
            try {
                WalletConnectProvider = window.WalletConnectProvider.default;
                provider = new WalletConnectProvider({
                    rpc: { 56: "https://bsc-dataseed1.binance.org/" },
                    qrcodeModalOptions: {
                        desktopLinks: []
                    }
                })
                if (provider.connected !== true) {
                    var acc = await provider.enable()
                    const web3 = new Web3(provider);
                    const _acc = acc[0]
                    if (web3.utils.isAddress(this.referralFromQR)) {
                        var _res = await this.$store.dispatch("moduleMain/saveReferral", { address: _acc, referral: this.referralFromQR })
                        // disconnect right away 
                        await provider.disconnect()
                        WalletConnectProvider = null
                        this.showProgressBar = false
                        // inform user
                        if (_res.status === 'OK') {
                            this.textColor = 'warning'
                            this.msg = "We've succesfully registered your referral code. You will now be directed to the app install instructions."
                            setTimeout(() => {
                                this.textColor = 'light'
                                this.msg = `You got here through referral link. In order to process your referral on iOS devices please click the 'process' button below to connect your wallet.`
                                this.$store.commit("moduleMain/setInstallReady", true)
                            }, 3000)
                        } else {
                            this.textColor = 'danger'
                            this.msg = "We couldn't register your referral code. You will now be directed to the app install instructions."
                            setTimeout(() => {
                                this.textColor = 'light'
                                this.msg = `You got here through referral link. In order to process your referral on iOS devices please click the 'process' button below to connect your wallet.`
                                this.$store.commit("moduleMain/setInstallReady", true)
                            }, 3000)
                        }
                    } else {
                        // disconnect right away 
                        await provider.disconnect()
                        WalletConnectProvider = null
                        this.showProgressBar = false
                        // inform user
                        this.textColor = 'danger'
                        this.msg = "The referral code isn't a valid wallet address. You will now be directed to the app install instructions."
                        setTimeout(() => {
                            this.textColor = 'light'
                            this.msg = `You got here through referral link. In order to process your referral on iOS devices please click the 'process' button below to connect your wallet.`
                            this.$store.commit("moduleMain/setInstallReady", true)
                        }, 3000)
                    }
                } else {
                    await provider.disconnect()
                    WalletConnectProvider = null
                    this.showProgressBar = false
                }
            } catch {
                await provider.disconnect()
                WalletConnectProvider = null
                this.showProgressBar = false
            }
        },
        async saveAddress(address) {
            var _res = await this.$store.dispatch("moduleMain/saveReferral", { address: address })
            console.log(_res)
        }
    },
    computed: {
        installModalOpen: {
            get() {
                return this.$store.getters['moduleMain/showInstallModal']
            }
        },
        installReady: {
            get() {
                return this.$store.getters['moduleMain/installReady']
            }
        },
        deviceInfo: {
            get() {
                return this.$store.getters['moduleMain/deviceInfo']
            }
        },
        referralFromQR: {
            get() {
                return this.$store.getters["moduleMain/referralFromQR"]
            }
        },
        host: {
            get() {
                return window.location.hostname
            }
        }
    }
})
</script>
<style scoped>
    .hub-review-button-container {
        bottom: 0;
        position: absolute;
        background: var(--ion-color-secondary);
    }
    .intro-img {
        align-items: center;
    }

    .splash-screen {
        position: absolute;
        z-index: 9999999999999999;
        background:var(--ion-color-secondary);
        width: 100%;
        height: 100%;
    }

    .splash-hidden {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 0.5s, opacity 0.5s linear;
    }

    .splash-container {
        height: 100%;
        width: 100%;
        position: relative;
    }

    .splash-vertical-center {
        width: 80%;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    ion-button {
        font-weight: 600;
    }
</style>