import axios from 'axios'
export const moduleMain = {
    namespaced: true,
    state () {
        return {
            daoOnly: false,
            installReady: false,
            referralFromQR: null,
            walletConnected: false, // set false when production build
            activeRoute: null,
            deviceInfo: {},
            showInstallModal: false,
            showSplashScreen: true,
            alertModal: {
                show: false,
                msg: null,
                variant: null
            },
            homeViewLoader: false,
            swapViewLoader: false,
            vaultViewLoader: false,
            vaultAllocViewLoader: false,
            vaultAutoAllocViewLoader: false,
            vaultDepositViewLoader: false,
            foundationViewLoader: false,
            disconnectWallet: false,
            showHomeAnalyticsModal: false,
            showHomeActionsModal: false,
            showHomeVaultModal: false,
            showHomeBuyModal: false,
            showHomeSellModal: false,
            showHomeAllocateModal: false,
            showHomeAutoModal: false,
            showHomeDepositModal: false,
            showHomeBalanceModal: false,
            showViewProgressBar: false,
            twitterFeed: [],
            showFoundationDepositModal: false,
            hideForBondAction: false,
            showFoundationWithdrawModal: false,
            showHomeBoardModal: false,
            downlineModalOpen: false,
            downlineInfo: {},
            scannerModalOpen: false,
            scanFromView: '',
            balanceSettings: {
                ark: 1,
                busd: 1,
                legacy: 1,
                foundation: 1
            }
        }
    },
    getters: {
        referralFromQR(state) {
            return state.referralFromQR
        },
        installReady(state) {
            return state.installReady
        },
        balanceSettings(state) {
            return state.balanceSettings
        },
        daoOnly(state) {
            return state.daoOnly
        },
        scanFromView(state) {
            return state.scanFromView
        },
        scannerModalOpen(state) {
            return state.scannerModalOpen
        },
        walletConnected(state) {
            return state.walletConnected
        },
        activeRoute(state) {
            return state.activeRoute
        },
        deviceInfo(state) {
            return state.deviceInfo
        },
        showInstallModal(state) {
            return state.showInstallModal
        },
        showSplashScreen(state) {
            return state.showSplashScreen
        },
        alertModal(state) {
            return state.alertModal
        },
        homeViewLoader(state) {
            return state.homeViewLoader
        },
        swapViewLoader(state) {
            return state.swapViewLoader
        },
        vaultViewLoader(state) {
            return state.vaultViewLoader
        },
        vaultAllocViewLoader(state) {
            return state.vaultAllocViewLoader
        },
        disconnectWallet(state) {
            return state.disconnectWallet
        },
        vaultAutoAllocViewLoader(state) {
            return state.vaultAutoAllocViewLoader
        },
        vaultDepositViewLoader(state) {
            return state.vaultDepositViewLoader
        },
        foundationViewLoader(state) {
            return state.foundationViewLoader
        },
        showHomeAnalyticsModal(state) {
            return state.showHomeAnalyticsModal
        },
        showHomeActionsModal(state) {
            return state.showHomeActionsModal
        },
        showHomeVaultModal(state) {
            return state.showHomeVaultModal
        },
        showHomeBuyModal(state) {
            return state.showHomeBuyModal
        },
        showHomeSellModal(state) {
            return state.showHomeSellModal
        },
        showHomeAllocateModal(state) {
            return state.showHomeAllocateModal
        },
        showHomeAutoModal(state) {
            return state.showHomeAutoModal
        },
        showHomeDepositModal(state) {
            return state.showHomeDepositModal
        },
        showHomeBalanceModal(state) {
            return state.showHomeBalanceModal
        },
        twitterFeed(state) {
            return state.twitterFeed
        },
        showViewProgressBar(state) {
            return state.showViewProgressBar
        },
        showFoundationDepositModal(state) {
            return state.showFoundationDepositModal
        },
        hideForBondAction(state) {
            return state.hideForBondAction
        },
        showFoundationWithdrawModal(state) {
            return state.showFoundationWithdrawModal
        },
        showHomeBoardModal(state) {
            return state.showHomeBoardModal
        },
        downlineModalOpen(state) {
            return state.downlineModalOpen
        },
        downlineInfo(state) {
            return state.downlineInfo
        }
    },
    mutations: {
        setReferralFromQR(state, val) {
            state.referralFromQR = val
        },
        setInstallReady(state, val) {
            state.installReady = val
        },
        setBalanceSettings(state, val) {
            state.balanceSettings = val
        },
        setScanFromView(state, val) {
            state.scanFromView = val
        },
        setScannerModalOpen(state, val) {
            state.scannerModalOpen = val
        },
        setDownlineModalOpen(state, val) {
            state.downlineModalOpen = val
        },
        setDownlineInfo(state, val) {
            state.downlineInfo = val
        },
        setWalletConnected(state, val) {
            state.walletConnected = val
        },
        setActiveRoute(state, val) {
            state.activeRoute = val
        },
        setDeviceInfo(state, val) {
            state.deviceInfo = val
        },
        triggerInstallModal(state, val) {
            state.showInstallModal = val
        },
        triggerSplashScreen(state, val) {
            state.showSplashScreen = val
        },
        setAlertModal(state, {show, msg, variant}) {
            state.alertModal.show = show
            state.alertModal.msg = msg
            state.alertModal.variant = variant
        },
        setHomeViewLoader(state, val) {
            state.homeViewLoader = val
        },
        setSwapViewLoader(state, val) {
            state.swapViewLoader = val
        },
        setVaultViewLoader(state, val) {
            state.vaultViewLoader = val
        },
        setVaultAllocViewLoader(state, val) {
            state.vaultAllocViewLoader = val
        },
        setDisconnectWallet(state, val) {
            state.disconnectWallet = val
        },
        setVaultAutoAllocViewLoader(state, val) {
            state.vaultAutoAllocViewLoader = val
        },
        setVaultDepositViewLoader(state, val) {
            state.vaultDepositViewLoader = val
        },
        setFoundationViewLoader(state, val) {
            state.foundationViewLoader = val
        },
        setShowHomeAnalyticsModal(state, val) {
            state.showHomeAnalyticsModal = val
        },
        setShowHomeBalanceModal(state, val) {
            state.showHomeBalanceModal = val
        },
        setShowHomeActionsModal(state, val) {
            state.showHomeActionsModal = val
        },
        setShowHomeVaultModal(state, val) {
            state.showHomeVaultModal = val
        },
        setShowHomeBuyModal(state, val) {
            state.showHomeBuyModal = val
        },
        setShowHomeSellModal(state, val) {
            state.showHomeSellModal = val
        },
        setShowHomeAllocateModal(state, val) {
            state.showHomeAllocateModal = val
        },
        setShowHomeAutoModal(state, val) {
            state.showHomeAutoModal = val
        },
        setShowHomeDepositModal(state, val) {
            state.showHomeDepositModal = val
        },
        setTwitterFeed(state, val) {
            state.twitterFeed = val
        },
        setShowViewProgressBar(state, val) {
            state.showViewProgressBar = val
        },
        setShowFoundationDepositModal(state, val) {
            state.showFoundationDepositModal = val
        },
        setHideForBondAction(state, val) {
            state.hideForBondAction = val
        },
        setShowFoundationWithdrawModal(state, val) {
            state.showFoundationWithdrawModal = val
        },
        setShowHomeBoardModal(state, val) {
            state.showHomeBoardModal = val
        }
    },
    actions: {
        async triggerModalAlert({ commit }, { show, msg, variant }) {
            await commit('setAlertModal', {
                show: show,
                msg: msg,
                variant: variant
            })
        },
        async GetTwitterFeed() {
            var _res = null
            try {
                await axios({
                    method: 'get',
                    url: 'https://api.coinportal.app/twitter/?account=1',
                    responseType: 'json',
                })
                .then((response) => {
                    _res = response.data
                })
                .catch(() => {
                    _res = 0
                })
            } catch(error) {
                _res = 0
            }
            return _res
        },
        async saveReferral({ state }, { address, referral }) {
            console.log(state.installReady)
            var _res = null
            try {
                await axios({
                    method: 'get',
                    url: `https://ark-mobile.scryptoking.com/api/save_referral/index.php?address=${address}&referral=${referral}`,
                    responseType: 'json',
                })
                .then((response) => {
                    _res = response.data
                })
                .catch(() => {
                    _res = 0
                })
            } catch(error) {
                _res = 0
            }
            return _res
        },
        async getReferral({ state }, { address }) {
            console.log(state.installReady)
            var _res = null
            try {
                await axios({
                    method: 'get',
                    url: `https://ark-mobile.scryptoking.com/api/get_referral/index.php?address=${address}`,
                    responseType: 'json',
                })
                .then((response) => {
                    _res = response.data
                })
                .catch(() => {
                    _res = 0
                })
            } catch(error) {
                _res = 0
            }
            return _res
        }
    }
}