import { swap } from './contract/index'
import { pcs } from './pcs/index'
export const moduleWeb3Swap = {
    namespaced: true,
    state () {
        return {
            // contract settings
            swapSpecs: swap,
            pcs: pcs,
            contractBscSwap: null,
            contractSwap: null,
            pcsRouter: null,
            // contract vars
            arkPrice_Swap: null
        }
    },
    getters: {
        arkPrice_Swap(state) {
            return state.arkPrice_Swap
        }
    },
    mutations: {
        Init_Contracts(state, { web3, web3bsc }) {
            state.contractBscSwap = new web3bsc.eth.Contract(state.swapSpecs.abi, state.swapSpecs.ca)
            state.contractSwap = new web3.eth.Contract(state.swapSpecs.abi, state.swapSpecs.ca)
            state.pcsRouter = new web3bsc.eth.Contract(state.pcs, state.swapSpecs.ca, "0x10ED43C718714eb63d5aA57B78B54704E256024E")
        },
        setArkPrice_Swap(state, val) {
            state.arkPrice_Swap = val
        }
    },
    actions: {
        async GetSlippage({ state }, { web3, amount, type }) {
            try {
                var slippage = 0.85;
                var USD = 0;
                var bnbInfo = await state.pcsRouter.methods.getAmountsOut(web3.utils.toWei("1"), ["0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c", "0xe9e7cea3dedca5984780bafc599bd69add087d56"]).call();
                var bnbPrice = Number(web3.utils.fromWei(bnbInfo[1]));
        
                switch (type) {
                    case "BNB":
                        USD = amount * bnbPrice;
                        break;
                    case "BUSD":
                        USD = amount;
                        break;
                }
        
                var operator = USD / 500 * 0.0011;
        
                return slippage - operator;
            }
            catch {
                return 0.85;
            }
        },
        async GetArkPrice_Swap({ state }, { web3 }) {
            try {
                var _val = await state.contractBscSwap.methods.getCurrentPriceInUSD().call();
                _val = web3.utils.fromWei(_val);
                // arkPrice = Number(_val);
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async ExpectedARKFromBUSD_Swap({ state }, { web3, amount }) {
            try {
                var _amount = web3.utils.toWei(amount);
                var _val = await state.contractBscSwap.methods.expectedARKFromBUSD(_amount).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async ExpectedARKFromBNB_Swap({ state }, { web3, amount }) {
            try {
                var _amount = web3.utils.toWei(amount);
                var _val = await state.contractBscSwap.methods.expectedARKFromBNB(_amount).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async ExpectedBUSDFromARK_Swap({ state, dispatch }, { web3, amount, address }) {
            try {
                var hasAcc = await dispatch('moduleWeb3Vault/HasAccount_Vault', { address: address }, { root: true })
                var _amount = web3.utils.toWei(amount);
                var _val;
        
                if (hasAcc) {
                    _val = await state.contractBscSwap.methods.expectedBUSDFromARK(_amount).call();
                    _val = web3.utils.fromWei(_val);
                } else {
                    _val = await state.contractBscSwap.methods.expectedBUSDFromARKWithDumpTax(_amount).call();
                    _val = web3.utils.fromWei(_val);
                }
        
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async GetLastSell_Swap({ state }, { address }) {
            try {
                var _val = await state.contractBscSwap.methods.lastSell(address).call();
                var date = new Date(Number(_val) * 1000);
                return date;
            }
            catch {
                return 0;
            }
        },
        async CheckBalanceAgainstInput_Swap({ dispatch }, { web3, currency, amount, address }) {
            try {
                var _val
        
                switch (currency) {            
                    case "busd":
                        _val = await dispatch("moduleWeb3Busd/getBUSDBalance", { web3: web3, address: address }, { root: true }) //await contractBscBUSD.methods.balanceOf(address).call();
                        break;
                    case "bnb":
                        _val = await dispatch("moduleAccount/GetBNBBalance", { web3: web3, address: address }, { root: true })
                        break;
                    case "ark":
                        _val = await dispatch("moduleWeb3Token/GetARKBalance_Token", { web3: web3, address: address }, { root: true })
                        break;
                    case "bond":
                        _val = await dispatch("moduleWeb3Foundation/GetShares_Bond", { web3: web3, address: address }, { root: true })
                        break;
                }
        
                if (Number(_val) < Number(amount)) {
                    return false;
                }
                else {
                    return true;
                }
            }
            catch (error) {
                return false;
            }
        },
        async buyArkWithBUSD_Swap({ state }, { val, min, ref, address }) {
            var _res = null
            // the time-out catch
            const timeOut = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        show: true,
                        msg: "We didn't receive the transaction confirmation within a reasonable time. Please check your wallet if your request came through",
                        variant: 'warning'
                    })
                }, 25000)
            })
            // the user action
            const userAction = new Promise((resolve, reject) => {
                state.contractSwap.methods.buyArkWithBUSD(val, min, ref).send({
                    from: address
                }).on("receipt", () => {
                    resolve({
                        show: true,
                        msg: "You've succesfully purchased ARK tokens with BUSD",
                        variant: 'success'
                    })
                }).on("error", (error) => {
                    reject({
                        show: true,
                        msg: error.message,
                        variant: 'warning'
                    })
                })
            })
            // do the request
            await Promise.race([userAction, timeOut])
            .then((res) => {
                _res = res
            })
            .catch((err) => {
                _res = err
            })
            return _res
        },
        async buyArkWithBNB_Swap({ state }, { val, min, ref, address }) {
            var _res = null
            // the time-out catch
            const timeOut = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        show: true,
                        msg: "We didn't receive the transaction confirmation within a reasonable time. Please check your wallet if your request came through",
                        variant: 'warning'
                    })
                }, 25000)
            })
            // the user action
            const userAction = new Promise((resolve, reject) => {
                state.contractSwap.methods.buyArkWithBNB(min, ref).send({
                    from: address,
                    value: val
                }).on("receipt", () => {
                    resolve({
                        show: true,
                        msg: "You've succesfully purchased ARK tokens with BNB",
                        variant: 'success'
                    })
                }).on("error", (error) => {
                    reject({
                        show: true,
                        msg: error.message,
                        variant: 'warning'
                    })
                })
            })
            // do the request
            await Promise.race([userAction, timeOut])
            .then((res) => {
                _res = res
            })
            .catch((err) => {
                _res = err
            })
            return _res
        },
        async sellArkForBUSD_Swap({ state }, { val, min, address }) {
            var _res = null
            // the time-out catch
            const timeOut = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        show: true,
                        msg: "We didn't receive the transaction confirmation within a reasonable time. Please check your wallet if your request came through",
                        variant: 'warning'
                    })
                }, 25000)
            })
            // the user action
            const userAction = new Promise((resolve, reject) => {
                state.contractSwap.methods.sellForBUSD(val, min).send({
                    from: address
                }).on("receipt", () => {
                    resolve({
                        show: true,
                        msg: "You've succesfully sold your ARK tokens for BUSD",
                        variant: 'success'
                    })
                }).on("error", (error) => {
                    reject({
                        show: true,
                        msg: error.message,
                        variant: 'warning'
                    })
                })
            })
            // do the request
            await Promise.race([userAction, timeOut])
            .then((res) => {
                _res = res
            })
            .catch((err) => {
                _res = err
            })
            return _res
        },
        async CompoundBondRewards_Swap({ state }, { address }) {
            var _res = null
            // the time-out catch
            const timeOut = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        show: true,
                        msg: "We didn't receive the transaction confirmation within a reasonable time. Please check your wallet if your request came through",
                        variant: 'warning'
                    })
                }, 25000)
            })
            // the user action
            const userAction = new Promise((resolve, reject) => {
                state.contractSwap.methods.compoundBondRewards().send({
                    from: address
                }).on("receipt", () => {
                    resolve({
                        show: true,
                        msg: "You've succesfully compounded your rewards",
                        variant: 'success'
                    })
                }).on("error", (error) => {
                    reject({
                        show: true,
                        msg: error.message,
                        variant: 'warning'
                    })
                })
            })
            // do the request
            await Promise.race([userAction, timeOut])
            .then((res) => {
                _res = res
            })
            .catch((err) => {
                _res = err
            })
            return _res
        },
        async ClaimBondRewards_Swap({ state }, { address }) {
            var _res = null
            // the time-out catch
            const timeOut = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        show: true,
                        msg: "We didn't receive the transaction confirmation within a reasonable time. Please check your wallet if your request came through",
                        variant: 'warning'
                    })
                }, 25000)
            })
            // the user action
            const userAction = new Promise((resolve, reject) => {
                state.contractSwap.methods.claimBondRewards().send({
                    from: address
                }).on("receipt", () => {
                    resolve({
                        show: true,
                        msg: "You've succesfully claimed your BOND rewards",
                        variant: 'success'
                    })
                }).on("error", (error) => {
                    reject({
                        show: true,
                        msg: error.message,
                        variant: 'warning'
                    })
                })
            })
            // do the request
            await Promise.race([userAction, timeOut])
            .then((res) => {
                _res = res
            })
            .catch((err) => {
                _res = err
            })
            return _res
        },
        async AddLiquidityWithBusd_Swap({ state }, { val1, val2, address }) {
            var _res = null
            // the time-out catch
            const timeOut = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        show: true,
                        msg: "We didn't receive the transaction confirmation within a reasonable time. Please check your wallet if your request came through",
                        variant: 'warning'
                    })
                }, 25000)
            })
            // the user action
            const userAction = new Promise((resolve, reject) => {
                state.contractSwap.methods.addLiquidityWithBusd(val1, val2).send({
                    from: address
                }).on("receipt", () => {
                    resolve({
                        show: true,
                        msg: "You've succesfully purchased BOND",
                        variant: 'success'
                    })
                }).on("error", (error) => {
                    reject({
                        show: true,
                        msg: error.message,
                        variant: 'warning'
                    })
                })
            })
            // do the request
            await Promise.race([userAction, timeOut])
            .then((res) => {
                _res = res
            })
            .catch((err) => {
                _res = err
            })
            return _res
        },
        async AddLiquidityWithArk_Swap({ state }, { val1, val2, address }) {
            var _res = null
            // the time-out catch
            const timeOut = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        show: true,
                        msg: "We didn't receive the transaction confirmation within a reasonable time. Please check your wallet if your request came through",
                        variant: 'warning'
                    })
                }, 25000)
            })
            // the user action
            const userAction = new Promise((resolve, reject) => {
                state.contractSwap.methods.addLiquidityWithArk(val1, val2).send({
                    from: address
                }).on("receipt", () => {
                    resolve({
                        show: true,
                        msg: "You've succesfully purchased BOND",
                        variant: 'success'
                    })
                }).on("error", (error) => {
                    reject({
                        show: true,
                        msg: error.message,
                        variant: 'warning'
                    })
                })
            })
            // do the request
            await Promise.race([userAction, timeOut])
            .then((res) => {
                _res = res
            })
            .catch((err) => {
                _res = err
            })
            return _res
        },
        async RemoveLiquidity_Swap({ state }, { val1, val2, address }) {
            var _res = null
            // the time-out catch
            const timeOut = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        show: true,
                        msg: "We didn't receive the transaction confirmation within a reasonable time. Please check your wallet if your request came through",
                        variant: 'warning'
                    })
                }, 25000)
            })
            // the user action
            const userAction = new Promise((resolve, reject) => {
                state.contractSwap.methods.removeLiquidity(val1, val2).send({
                    from: address
                }).on("receipt", () => {
                    resolve({
                        show: true,
                        msg: "You've succesfully withdrawn BOND",
                        variant: 'success'
                    })
                }).on("error", (error) => {
                    reject({
                        show: true,
                        msg: error.message,
                        variant: 'warning'
                    })
                })
            })
            // do the request
            await Promise.race([userAction, timeOut])
            .then((res) => {
                _res = res
            })
            .catch((err) => {
                _res = err
            })
            return _res
        },
        async ClaimLegacyRewards_Swap({ state }, { address }) {
            var _res = null
            // the time-out catch
            const timeOut = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        show: true,
                        msg: "We didn't receive the transaction confirmation within a reasonable time. Please check your wallet if your request came through",
                        variant: 'warning'
                    })
                }, 25000)
            })
            // the user action
            const userAction = new Promise((resolve, reject) => {
                state.contractSwap.methods.claimLegacyRewards().send({
                    from: address
                }).on("receipt", () => {
                    resolve({
                        show: true,
                        msg: "You've succesfully claimed your Legacy rewards",
                        variant: 'success'
                    })
                }).on("error", (error) => {
                    reject({
                        show: true,
                        msg: error.message,
                        variant: 'warning'
                    })
                })
            })
            // do the request
            await Promise.race([userAction, timeOut])
            .then((res) => {
                _res = res
            })
            .catch((err) => {
                _res = err
            })
            return _res
        }
    }
}