// import axios from 'axios'
export const moduleSwap = {
    namespaced: true,
    state () {
        return {
            showBackdrop: false,
            chosenBuyCurrency: 'busd'
        }
    },
    getters: {
        showBackdrop(state) {
            return state.showBackdrop
        },
        chosenBuyCurrency(state) {
            return state.chosenBuyCurrency
        }
    },
    mutations: {
        setBackdrop(state, val) {
            state.showBackdrop = val
        },
        setChosenBuyCurrency(state, val) {
            state.chosenBuyCurrency = val
        }
    },
    actions: {

    }
}