import { vault } from './contract/index'
import { autoAlloc } from './contract/autoAlloc/index'
import axios from 'axios'
export const moduleWeb3Vault = {
    namespaced: true,
    state () {
        return {
            // contract settings
            vaultSpecs: vault,
            autoAllocSpecs: autoAlloc,
            contractBscVault: null,
            contractVault: null,
            contractBscAutoAlloc: null,
            contractAutoAlloc: null,
            // contract vars
            needTo: false,
            cwrPresets: [
                {
                    cwr: '1.00',
                    compound: 50,
                    withdraw: 50,
                    nftLevel: 0
                },
                {
                    cwr: '1.33',
                    compound: 57,
                    withdraw: 43,
                    nftLevel: 0
                },
                {
                    cwr: '1.50',
                    compound: 60,
                    withdraw: 40,
                    nftLevel: 0
                },
                {
                    cwr: '1.80',
                    compound: 64,
                    withdraw: 36,
                    nftLevel: 1
                },
                {
                    cwr: '2.50',
                    compound: 71,
                    withdraw: 29,
                    nftLevel: 1
                },
                {
                    cwr: '3.67',
                    compound: 78,
                    withdraw: 22,
                    nftLevel: 2
                },
                {
                    cwr: '6.00',
                    compound: 85,
                    withdraw: 15,
                    nftLevel: 2
                },
                {
                    cwr: '13.00',
                    compound: 92,
                    withdraw: 8,
                    nftLevel: 3
                }
            ],
            totalAccounts_Vault: 0,
            sparkWinners_Vault: [],
            sparkPayOut_Vault: 0,
            sparkPrizePool_Vault: 0,
            cwr: 0,
            maxCwr: 0,
            out: 0,
            availRewards: 0,
            principal: 0,
            deposits: 0,
            withdrawn: 0,
            airdropBalance: 0,
            roi: 0,
            newDeposits: 0,
            compounds: 0,
            airdropped: 0,
            maxPayout: 0,
            ndv: 0,
            airdropReceived: 0,
            tax: 0,
            referralRewards: 0,
            availableRewards: 0,
            mpp: 0,
            paidOut: 0,
            dayPrice: 0,
            autoAllocProfile: null,
            autoAllocSettingsDays: null,
            autoAllocSettingsCWR: null,
            paidUntil: new Date(),
            myReferree: 0
        }
    },
    getters: {
        totalAccounts_Vault(state) {
            return state.totalAccounts_Vault
        },
        sparkWinners_Vault(state) {
            return state.sparkWinners_Vault
        },
        sparkPayOut_Vault(state) {
            return state.sparkPayOut_Vault
        },
        sparkPrizePool_Vault(state) {
            return state.sparkPrizePool_Vault
        },
        cwr(state) {
            return state.cwr
        },
        maxCwr(state) {
            return state.maxCwr
        },
        cwrPresets(state) {
            return state.cwrPresets
        },
        out(state) {
            return state.out
        },
        availRewards(state) {
            return state.availRewards
        },
        principal(state) {
            return state.principal
        },
        deposits(state) {
            return state.deposits
        },
        withdrawn(state) {
            return state.withdrawn
        },
        airdropBalance(state) {
            return state.airdropBalance
        },
        roi(state) {
            return state.roi
        },
        newDeposits(state) {
            return state.newDeposits
        },
        compounds(state) {
            return state.compounds
        },
        airdropped(state) {
            return state.airdropped
        },
        maxPayout(state) {
            return state.maxPayout
        },
        ndv(state) {
            return state.ndv
        },
        airdropReceived(state) {
            return state.airdropReceived
        },
        tax(state) {
            return state.tax
        },
        referralRewards(state) {
            return state.referralRewards
        },
        mpp(state) {
            return state.mpp
        },
        availableRewards(state) {
            return state.availableRewards
        },
        paidOut(state) {
            return state.paidOut
        },
        dayPrice(state) {
            return state.dayPrice
        },
        autoAllocProfile(state) {
            return state.autoAllocProfile
        },
        autoAllocSettingsDays(state) {
            return state.autoAllocSettingsDays
        },
        autoAllocSettingsCWR(state) {
            return state.autoAllocSettingsCWR
        },
        paidUntil(state) {
            return state.paidUntil
        },
        myReferree(state) {
            return state.myReferree
        }
    },
    mutations: {
        Init_Contracts(state, { web3, web3bsc }) {
            state.contractBscVault = new web3bsc.eth.Contract(state.vaultSpecs.abi, state.vaultSpecs.ca)
            state.contractVault = new web3.eth.Contract(state.vaultSpecs.abi, state.vaultSpecs.ca)
            state.contractBscAutoAlloc = new web3bsc.eth.Contract(state.autoAllocSpecs.abi, state.autoAllocSpecs.ca)
            state.contractAutoAlloc = new web3.eth.Contract(state.autoAllocSpecs.abi, state.autoAllocSpecs.ca)
        },
        setTotalAccounts_Vault(state, val) {
            state.totalAccounts_Vault = val
        },
        setSparkWinners_Vault(state, val) {
            state.sparkWinners_Vault = val
        },
        setCwr(state, val) {
            state.cwr = val
        },
        setMaxCwr(state, val) {
            state.maxCwr = val
        },
        setOut(state, val) {
            state.out = val
        },
        setAvailRewards(state, val) {
            state.availRewards = val
        },
        setPrincipal(state, val) {
            state.principal = val
        },
        setDeposits(state, val) {
            state.deposits = val
        },
        setWithdrawn(state, val) {
            state.withdrawn = val
        },
        setAirdropBalance(state, val) {
            state.airdropBalance = val
        },
        setRoi(state, val) {
            state.roi = val
        },
        setNewDeposits(state, val) {
            state.newDeposits = val
        },
        setCompounds(state, val) {
            state.compounds = val
        },
        setAirdropped(state, val) {
            state.airdropped = val
        },
        setMaxPayout(state, val) {
            state.maxPayout = val
        },
        setNdv(state, val) {
            state.ndv = val
        },
        setAirdropReceived(state, val) {
            state.airdropReceived = val
        },
        setTax(state, val) {
            state.tax = val
        },
        setReferralRewards(state, val) {
            state.referralRewards = val
        },
        setMpp(state, val) {
            state.mpp = val
        },
        setAvailableRewards(state, val) {
            state.availableRewards = val
        },
        setPaidOut(state, val) {
            state.paidOut = val
        },
        setDayPrice(state, val) {
            state.dayPrice = val
        },
        setAutoAllocProfile(state, val) {
            state.autoAllocProfile = val
        },
        setAutoAllocSettingsDays(state, val) {
            state.autoAllocSettingsDays = val
        },
        setAutoAllocSettingsCWR(state, val) {
            state.autoAllocSettingsCWR = val
        },
        setPaidUntil(state, val) {
            state.paidUntil = val
        },
        setSparkPayOut_Vault(state, val) {
            state.sparkPayOut_Vault = val
        },
        setSparkPrizePool_Vault(state, val) {
            state.sparkPrizePool_Vault = val
        },
        setMyReferree(state, val) {
            state.myReferree = val
        }
    },
    actions: {
        testIt() {
            console.log('test')
        },
        async GetInvestors_Vault() {
            var _res = null
            try {
                await axios({
                    method: 'post',
                    url: 'https://api.arkfi.io/investors',
                    responseType: 'json',
                    data: `{"investors": true}`,
                })
                .then((response) => {
                    _res = response.data.data.total
                })
                .catch(() => {
                    _res = 0
                })
            } catch(error) {
                _res = 0
            }
            return _res
        },
        async GetSparkWinners_Vault() {
            var _res = null
            try {
                await axios({
                    method: 'get',
                    url: 'https://api.arkfi.io/spark?events',
                    responseType: 'json'
                })
                .then((response) => {
                    _res = response.data.data
                    _res = _res.sort(function (a, b) {
                        return b.data.winnerNumber - a.data.winnerNumber;
                    })
                })
                .catch(() => {
                    _res = []
                })
            } catch {
                _res = []
            }
            return _res
        },
        async LastAction_Vault({ state }, { address }) {
            try {
                var _val = await state.contractBscVault.methods.lastAction(address).call()
                _val = new Date(Number(_val) * 1000)
                return _val
            } catch {
                return new Date();
            }
        },
        async GetYourReferrer_Vault({ state }, { address }) {
            try {
                var _val = await state.contractBscVault.methods.referrerOf(address).call();        
                return _val;
            }
            catch (error) {
                return "";
            }
        },
        async GetAutoPaidUntil_Vault({ state }, { address }) {
            try {
                var _val = await state.contractBscAutoAlloc.methods.paidUntil(address).call();
                return new Date(Number(_val) * 1000);
            }
            catch (error) {
                return new Date();
            }
        },
        async GetAvailableRewards_Vault({ state }, { web3, address }) {
            try {
                var _val = await state.contractBscVault.methods.getAvailableReward(address).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(2);
            }
            catch (error) { 
                return 0;
            }
        },
        async GetExpectedCWR_Vault({ state, dispatch }, { address, withdraw, compound, airdrop }) {
            try {
                var _lastAction = await dispatch('LastAction_Vault', { address: address })
                var _now = new Date();
                var difference = _now.getTime() - _lastAction.getTime();
                var timeSinceLastAction = Math.floor(difference / 1000);
                timeSinceLastAction = timeSinceLastAction > 86400 ? 86400 : timeSinceLastAction;
                var _val = await state.contractBscVault.methods.getRollingAverageCwr(address, timeSinceLastAction.toString(), withdraw, compound, airdrop).call();
                return (Number(_val) / 1000).toFixed(2);
            }
            catch (error) {
                return 0;
            }
        },
        async GetSparkPrizePool_Vault({ state }, { web3 }) {
            try {
                var _val = await state.contractBscVault.methods.sparkPot().call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(0);
            }
            catch {
                return 0;
            }
        },
        async GetSparkPayout_Vault({ state }, { web3 }) {
            try {
                var _val = await state.contractBscVault.methods.totalPrizeMoneyPaid().call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(0);
            }
            catch {
                return 0;
            }
        },
        async GetROI_Vault({ state }, { address }) {
            try {
                var _val = await state.contractBscVault.methods.roi(address).call(); // checkRoi(address).call();
                return Number(_val) / 10;
            }
            catch {
                return 0;
            }
        },
        async GetReferrerOf_Vault({ state }, { address }) {
            try {
                var _val = await state.contractBscVault.methods.referrerOf(address).call();
                return _val;
            }
            catch {
                return 0;
            }
        },
        async GetPrincipal_Vault({ state }, {web3, address }) {
            try {
                var _val = await state.contractBscVault.methods.principalBalance(address).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async GetDeposits_Vault({ state }, {web3, address }) {
            try {
                var _val = await state.contractBscVault.methods.deposits(address).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async GetDepositsForAddress_Vault({ state }, {web3, address }) {
            try {
                var _val = await state.contractBscVault.methods.deposits(address).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async GetNewDeposits_Vault({ state }, {web3, address }) {
            try {
                var _val = await state.contractBscVault.methods.newDeposits(address).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async GetOut_Vault({ state }, { web3, address }) {
            try {
                var _val = await state.contractBscVault.methods.out(address).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async GetWithdrawn_Vault({ state }, {web3, address }) {
            try {
                var _val = await state.contractBscVault.methods.withdrawn(address).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async GetAirdropped_Vault({ state }, {web3, address }) {
            try {
                var _val = await state.contractBscVault.methods.airdropped(address).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async GetAirdroppedForAcc_Vault({ state }, {web3, address }) {
            try {
                var _val = await state.contractBscVault.methods.airdropped(address).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async GetAirdropBalance_Vault({ state }, {web3, address }) {
            try {
                var _val = await state.contractBscVault.methods.airdropBalance(address).call();
                _val = web3.utils.fromWei(_val);
                // var airdropBalance = Number(_val);
                _val = Math.floor(Number(_val) * 100) / 100;        
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async GetAirdropBalanceForAcc_Vault({ state }, {web3, address }) {
            try {
                var _val = await state.contractBscVault.methods.airdropBalance(address).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async GetCompounds_Vault({ state }, {web3, address }) {
            try {
                var _val = await state.contractBscVault.methods.compounds(address).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async GetAirdropsReceived_Vault({ state }, {web3, address }) {
            try {
                var _val = await state.contractBscVault.methods.airdropsReceived(address).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async GetAirdropsReceivedForAcc_Vault({ state }, {web3, address }) {
            try {
                var _val = await state.contractBscVault.methods.airdropsReceived(address).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },    
        async GetTaxes_Vault({ state }) {
            try {
                var _val = await state.contractBscVault.methods.liqTax().call();
                var _val2 = await state.contractBscVault.methods.vaultTax().call();
        
                var _val3 = Number(_val) + Number(_val2);
        
                return _val3;
            }
            catch {
                return 10;
            }
        },
        async GetMaxPayout_Vault({ state }, { address }) {
            try {
                var _val = await state.contractBscVault.methods.checkForMaxPayoutPercent(address).call();
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async GetTax_Vault({ state }, { address }) {
            try {
                var _val = await state.contractBscVault.methods.tax(address).call();
                return Number(_val) // .toFixed(0);
            }
            catch {
                return 0;
            }
        },
        async GetCWR_Vault({ state }, { address }) {
            try {
                var _val = await state.contractBscVault.methods.cwr(address).call();
                return (Number(_val) / 1000) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async GetReferalRewards_Vault({ state }, { web3, address }) {
            try {
                var _val = await state.contractBscVault.methods.roundRobinRewards(address).call();
                var _val2 = await state.contractBscVault.methods.directRewards(address).call();
                _val = web3.utils.fromWei(_val);
                _val2 = web3.utils.fromWei(_val2);
                _val = Number(_val) + Number(_val2);
                return Number(_val)
            }
            catch {
                return 0;
            }
        },
        async GetBondValue_Vault({ state }, { web3, address }) {
            try {
                var _val = await state.contractBscVault.methods.getBondValue(address).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async CalculateUSDValueOfBond_Vault({ state }, { web3, amount }) {
            try {
                var _amount = web3.utils.toWei(amount);
                var _val = await state.contractBscVault.methods.calculateUsdValueOfBond(_amount).call();
                _val = web3.utils.fromWei(_val);
        
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async GetTotalAccounts_Vault({ state }) {
            try {
                var _val = await state.contractBscVault.methods.totalAccounts().call();
                return _val;
            }
            catch {
                return 0;
            }
        },
        async GetLevelForInvestor_Vault({ state }, { web3, address }) {
            try {
                var _val = await state.contractBscVault.methods.getLevelForInvestor(address).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(0);
            }
            catch {
                return 0;
            }
        },
        async GetNDVAmountForAcc_Vault({ state }, { web3, address }) {
            try {
                var _val = await state.contractBscVault.methods.checkNdv(address).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async GetNDVAmount_Vault({ state }, { web3, address }) {
            try {
                var _val = await state.contractBscVault.methods.checkNdv(address).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async HasAccount_Vault({ state }, { address }) {
            try {
                var _val = await state.contractBscVault.methods.hasAccount(address).call();
                return _val;
            } catch {
                return false;
            }
        },
        async PricePerAction_Vault({ state }, { web3 }) {
            try {
                var _val = await state.contractBscAutoAlloc.methods.pricePerAction().call()
                _val = web3.utils.fromWei(_val)
                return _val
            }
            catch {
                return 0;
            }
        },
        async HasAutoAlloc_Vault({ state }, { address }) {
            state.needTo = true
            var _res = null
            try {
                await axios({
                    method: 'post',
                    url: 'https://api.arkfi.io/cwr',
                    data: `{"investor": "${address}"}`,
                    crossDomain: true,
                    responseType: 'json'
                })
                .then((response) => {
                    _res = response.data.data
                })
                .catch(() => {
                    _res = null
                })
            } catch {
                _res = null
            }
            return _res
        },
        /*
        async TakeAction_Vault({ state }, { withdraw, compound, airdrop, address, autoDeposit, autoBond }) {
            try {
                await state.contractVault.methods.takeAction(withdraw, compound, airdrop, false, autoDeposit, autoBond).send({
                    from: address
                }).on("receipt", function () {
                    return {
                        show: true,
                        msg: 'Your vault action has been executed succesfully.',
                        variant: 'success'
                    }
                }).on("error", function (error) {
                    return {
                        show: true,
                        msg: error.message,
                        variant: 'warning'
                    }
                })
            } 
            catch(error) {
                return {
                    show: true,
                    msg: error.message,
                    variant: 'warning'
                }
            }
        },
        */
        async TakeAction_Vault({ state }, { withdraw, compound, airdrop, address, autoDeposit, autoBond }) {
            var _res = null
            // the time-out catch
            const timeOut = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        show: true,
                        msg: "We didn't receive the transaction confirmation within a reasonable time. Please check your wallet if your request came through",
                        variant: 'warning'
                    })
                }, 25000)
            })
            // the user action
            const userAction = new Promise((resolve, reject) => {
                state.contractVault.methods.takeAction(withdraw, compound, airdrop, false, autoDeposit, autoBond).send({
                    from: address
                })
                .on("receipt", () => {
                    resolve({
                        show: true,
                        msg: 'Your vault action has been executed succesfully',
                        variant: 'success'
                    })
                }).on("error", (error) => {
                    reject({
                        show: true,
                        msg: error.message,
                        variant: 'warning'
                    })
                })
            })
            // do the request
            await Promise.race([userAction, timeOut])
            .then((res) => {
                _res = res
            })
            .catch((err) => {
                _res = err
            })
            return _res
        },
        async RegisterForAutoAllocation_Vault({ state }, { wPerc, cPerc, aPerc, autoSell, autoDepo, autoBond, days, address, bnbs }) {
            var _res = null
            // the time-out catch
            const timeOut = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        show: true,
                        msg: "We didn't receive the transaction confirmation within a reasonable time. Please check your wallet if your request came through",
                        variant: 'warning'
                    })
                }, 25000)
            })
            // the user action
            const userAction = new Promise((resolve, reject) => {
                state.contractAutoAlloc.methods.registerForAutoAllocation(wPerc, cPerc, aPerc, autoSell, autoDepo, autoBond, days).send({
                    from: address, value: bnbs
                }).on("receipt", () => {
                    resolve({
                        show: true,
                        msg: 'Auto allocation has been executed succesfully',
                        variant: 'success'
                    })
                }).on("error", (error) => {
                    reject({
                        show: true,
                        msg: error.message,
                        variant: 'danger'
                    })
                })
            })
            // do the request
            await Promise.race([userAction, timeOut])
            .then((res) => {
                _res = res
            })
            .catch((err) => {
                _res = err
            })
            return _res
        },
        async ChangeAutoAllocation_Vault({ state }, { wPerc, cPerc, aPerc, autoSell, autoDepo, autoBond, address }) {
            var _res = null
            // the time-out catch
            const timeOut = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        show: true,
                        msg: "We didn't receive the transaction confirmation within a reasonable time. Please check your wallet if your request came through",
                        variant: 'warning'
                    })
                }, 25000)
            })
            // the user action
            const userAction = new Promise((resolve, reject) => {
                state.contractAutoAllo.methods.changeAutoAllocation(wPerc, cPerc, aPerc, autoSell, autoDepo, autoBond).send({
                    from: address
                }).on("receipt", () => {
                    resolve({
                        show: true,
                        msg: 'Auto allocation has been changed succesfully',
                        variant: 'success'
                    })
                }).on("error", (error) => {
                    reject({
                        show: true,
                        msg: error.message,
                        variant: 'danger'
                    })
                })
            })
            // do the request
            await Promise.race([userAction, timeOut])
            .then((res) => {
                _res = res
            })
            .catch((err) => {
                _res = err
            })
            return _res
        },
        async ExtendAutoAllocation_Vault({ state }, { days, address, bnbs }) {
            var _res = null
            // the time-out catch
            const timeOut = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        show: true,
                        msg: "We didn't receive the transaction confirmation within a reasonable time. Please check your wallet if your request came through",
                        variant: 'warning'
                    })
                }, 25000)
            })
            // the user action
            const userAction = new Promise((resolve, reject) => {
                state.contractAutoAllo.methods.extendAutoAllocation(days).send({
                    from: address, value: bnbs
                }).on("receipt", () => {
                    resolve({
                        show: true,
                        msg: 'Auto allocation has been extended succesfully.',
                        variant: 'success'
                    })
                }).on("error", (error) => {
                    reject({
                        show: true,
                        msg: error.message,
                        variant: 'danger'
                    })
                })
            })
            // do the request
            await Promise.race([userAction, timeOut])
            .then((res) => {
                _res = res
            })
            .catch((err) => {
                _res = err
            })
            return _res
        },
        async DepositARK_Vault({ state }, { valDepo, ref, address }) {
            var _res = null
            // the time-out catch
            const timeOut = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        show: true,
                        msg: "We didn't receive the transaction confirmation within a reasonable time. Please check your wallet if your request came through",
                        variant: 'warning'
                    })
                }, 25000)
            })
            // the user action
            const userAction = new Promise((resolve, reject) => {
                state.contractVault.methods.deposit(valDepo, ref).send({
                    from: address
                }).on("receipt", () => {
                    resolve({
                        show: true,
                        msg: 'Your ARK has been deposited succesfully.',
                        variant: 'success'
                    })
                }).on("error", (error) => {
                    reject({
                        show: true,
                        msg: error.message,
                        variant: 'danger'
                    })
                })
            })
            // do the request
            await Promise.race([userAction, timeOut])
            .then((res) => {
                _res = res
            })
            .catch((err) => {
                _res = err
            })
            return _res
        },
        SetAutoAllocCWR_Vault({ state, commit }, { withdrawVal }) {
            var r = state.cwrPresets.find(o => {
                return o.withdraw === Number(withdrawVal)
            })
            commit("setAutoAllocSettingsCWR", r.cwr)
        },
        async GetMaxCWR_Vault({ dispatch }, { address }) {
            try {
                var _val = await dispatch("moduleWeb3Legacy/GetLevelOfAccount_Legacy", { address: address }, { root: true })
                var returnVal = 0;
        
                switch (_val) {
                    default:
                        returnVal = 1.5;
                        break;
                    case 3:
                        returnVal = 2.5;
                        break
                    case 9:
                        returnVal = 6;
                        break;
                    case 15:
                        returnVal = 13;
                        break;
                }
        
                return Number(returnVal) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async AutoAllocateAllowed_Vault({ state, dispatch }, { address, days, widthdraw, compound }) {
            try {
                // var autoRedeposit = false;
                var prediction = [];
                var hourPrediction = [];
                /*
                var predictionDays = 0
                var predictionCWR = 0
                */
                var _prediction = null

                var _maxCWR = Number(await dispatch('GetMaxCWR_Vault', { address: address }));
        
                for (let i = 1; i <= days; i++) {
                    var _val = await state.contractBscVault.methods.getRollingAverageCwr(address, (86400 * i).toString(), widthdraw, compound, 0).call();
                    prediction.push(Number(_val) / 1000);
                }
        
                for (let i = 1; i <= 1; i++) {
                    var _val2 = await state.contractBscVault.methods.getRollingAverageCwr(address, (3600 * i).toString(), widthdraw, compound, 0).call();
                    hourPrediction.push(Number(_val2) / 1000);
                }
        
                for (let pred in prediction) {
                    _prediction = prediction[pred];
                    /*
                    predictionDays = count;
                    predictionCWR = _prediction;
                    */
        
                    if (_prediction > _maxCWR) {
                        /*
                        FillModals();
                        OpenModal(modalAutoCWRCap);
                        */
                        return 'These auto allocation settings will result in a CWR above your current CWR limit.'
                    }
                    if (_prediction <= 0.750) {
                        /*
                        FillModals();
                        OpenModal(modalAutoCWRFloor);
                        */
                        return 'These auto allocation settings will result in a CWR drop below 0.75.'
                    }
        
                }
        
        
                for (let pred in hourPrediction) {
                    _prediction = hourPrediction[pred];
                    /*
                    predictionDays = 1;
                    predictionCWR = _prediction;
                    */
        
                    if (_prediction > _maxCWR) {
                        /*
                        FillModals();
                        OpenModal(modalAutoCWRCap);
                        */
                        return 'These auto allocation settings will result in a CWR above your current CWR limit.'
                    }
                    if (_prediction <= 0.75) {
                        /*
                        FillModals();
                        OpenModal(modalAutoCWRFloor);
                        */
                        return 'These auto allocation settings will result in a CWR drop below 0.75.'
                    }

                }
        
                return 'confirm';
            }
            catch {
                // console.log(error);
                return 'Something went worng. Please try again.'
            }
        }
    }
}