import axios from 'axios'
export const moduleMenu = {
    namespaced: true,
    state () {
        return {
            lastSparkDistribution: null,
            progressValue: 0,
            podCasts: []
        }
    },
    getters: {
        progressValue(state) {
            return state.progressValue
        },
        podCasts(state) {
            return state.podCasts
        }
    },
    mutations: {
        setLastSparkDistribution(state, val) {
            state.lastSparkDistribution = val
        },
        setSparkProgressOrb(state) {
            if (state.lastSparkDistribution !== null) {
                var _done = 0
                var _now = new Date()
                _now = _now.getTime()
                var _dueDate = state.lastSparkDistribution.getTime()
                var _startDueDate = state.lastSparkDistribution.getTime() - (60 * 60 * 24 * 1000)

                var _percPerSecond = 100 / (60 * 60 * 24 * 1000)

                if (_now < _dueDate) {
                    _done = _now - _startDueDate
                } else {
                    _done = _now - _dueDate
                }

                var _val = Math.ceil(_done*_percPerSecond)
                state.progressValue = _val
            }
        }
    },
    actions: {
        async GetPodcasts({state}) {
            await axios({
                method: 'get',
                url: 'https://ark-mobile.scryptoking.com/api/podcasts/',
                responseType: 'json'
            })
            .then((response) => {
                state.podCasts = response.data
            })
            .catch(() => {

            })
        }
    }
}