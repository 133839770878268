import { busd } from "./contract/index"
import { swap } from "../swap/contract/index"
import { legacy } from '../legacy/contract/index'
export const moduleWeb3Busd = {
    namespaced: true,
    state () {
        return {
            legacySpecs: legacy,
            // contract settings swap
            swapSpecs: swap,
            // contract settings BUSD
            busdSpecs: busd,
            contractBscBUSD: null,
            contractBUSD: null,
            // contract vars
            busdBalance: 0,
            busdApproved: null,
            ilcReserves: 0
        }
    },
    getters: {
        busdBalance(state) {
            return state.busdBalance
        },
        busdApproved(state) {
            return state.busdApproved
        },
        ilcReserves(state) {
            return state.ilcReserves
        }
    },
    mutations: {
        Init_Contracts(state, { web3, web3bsc }) {
            state.contractBscBUSD = new web3bsc.eth.Contract(state.busdSpecs.abi, state.busdSpecs.ca)
            state.contractBUSD = new web3.eth.Contract(state.busdSpecs.abi, state.busdSpecs.ca)
        },
        setBUSDBalance(state, val) {
            state.busdBalance = val
        },
        setBUSDApproved(state, val) {
            state.busdApproved = val
        },
        setILCReserves(state, val) {
            state.ilcReserves = val
        }
    },
    actions: {
        async GetILCReserves_Swap({ state }, { web3 }) {
            try {
                var _val = await state.contractBscBUSD.methods.balanceOf(state.swapSpecs.ca).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(0);
            }
            catch {
                return 0;
            }
        },
        async getBUSDBalance({ state }, { web3, address }) {
            try {
                var _val = await state.contractBscBUSD.methods.balanceOf(address).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async ApproveBUSD_Swap({ state }, { web3, address }) {
            var _res = null
            // the time-out catch
            const timeOut = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        show: true,
                        msg: "We didn't receive the transaction confirmation within a reasonable time. Please check your wallet if your request came through",
                        variant: 'warning'
                    })
                }, 25000)
            })
            // the user action
            const userAction = new Promise((resolve, reject) => {
                state.contractBUSD.methods.approve(state.swapSpecs.ca, web3.utils.toWei("100000")).send({
                    from: address
                })
                .on('receipt', () => {
                    resolve({
                        show: true,
                        msg: 'Your BUSD has been approved',
                        variant: 'success'
                    })
                })
                .on('error', (error) => {
                    reject({
                        show: true,
                        msg: error.message,
                        variant: 'warning'
                    })
                })
            })
            // do the request
            await Promise.race([userAction, timeOut])
            .then((res) => {
                _res = res
            })
            .catch((err) => {
                _res = err
            })
            return _res
        },
        async Approve_Legacy({ state }, { web3, address }) {
            var _res = null
            // the time-out catch
            const timeOut = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        show: true,
                        msg: "We didn't receive the transaction confirmation within a reasonable time. Please check your wallet if your request came through",
                        variant: 'warning'
                    })
                }, 25000)
            })
            // the user action
            const userAction = new Promise((resolve, reject) => {
                state.contractBUSD.methods.approve(state.legacySpecs.ca, web3.utils.toWei("10000")).send({
                    from: address
                })
                .on('receipt', () => {
                    resolve({
                        show: true,
                        msg: "Approval of Legacy was succesful",
                        variant: 'success'
                    })
                })
                .on('error', (error) => {
                    reject({
                        show: true,
                        msg: error.message,
                        variant: 'danger'
                    })
                });
            })
            // do the request
            await Promise.race([userAction, timeOut])
            .then((res) => {
                _res = res
            })
            .catch((err) => {
                _res = err
            })
            return _res
        },
        async CheckApproval_Legacy({ state }, { web3, address, amount }) {
            try {
                var allowance = await state.contractBscBUSD.methods.allowance(address, state.legacySpecs.ca).call();
                allowance = Number(web3.utils.fromWei(allowance));
                amount = amount <= 0 ? 100 : 100;
        
                if (Number(allowance) >= amount) {
                    return true;
                }
                else {
                    return false;
                }
            }
            catch (error) {
                return false;
            }
        },
        async CheckApprovalBUSD_Swap({ state }, { web3, address, amount }) {
            try {
                var allowance = await state.contractBscBUSD.methods.allowance(address, state.swapSpecs.ca).call();
                allowance = Number(web3.utils.fromWei(allowance));
                if (Number(allowance) >= amount) {
                    return true
                }
                else {
                    return false
                }
            }
            catch (error) {
                // alert(error.message);
                return false
            }
        }
    }
}