export const moduleWeb3Main = {
    namespaced: true,
    state () {
        return {
            web3: null,
            provider: null,
            wallets: []
        }
    },
    getters: {
        web3(state) {
            return state.web3
        },
        provider(state) {
            return state.provider
        },
        wallets(state) {
            return state.wallets
        }
    },
    mutations: {
        setWeb3(state, val) {
            state.web3 = val
        },
        setProvider(state, val) {
            state.provider = val
        },
        modifyWallets(state, { action, val }) {
            if (action === 'add') {
                state.wallets.push(val)
            } else {
                state.wallets.splice(val, 1)
            }
        }
    },
    actions: {

    }
}