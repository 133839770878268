export const moduleHome = {
    namespaced: true,
    state () {
        return {
            qrModalOpen: false,
            boardVideos: [
                {
                    id: '1',
                    title: 'Ecosystem',
                    animations: [
                        {
                            id: '1a',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_1a/A2_E_A_1a_07F.json',
                            caption: 'Ark Fi is an ecosystem of high yield decentralized finance (DeFi) products built on the Binance Smart Chain (BSC) for security and scalability.'
                        },
                        {
                            id: '1b',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_1b/A2_E_A_1b_07F.json',
                            caption: 'Ark Fi products are permissionless and anyone can invest by connecting a Web3 wallet to the decentralized application on a browser enabled device.'
                        },
                        {
                            id: '1c',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_1c/A2_E_A_1c_07F.json',
                            caption: 'Ark Fi products work together to support the sustainability and growth of the protocol while generating a return on investment for investors.'
                        },
                        {
                            id: '1d',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_1d/A2_E_A_1d_07F.json',
                            caption: 'When crypto is deposited into any of the Ark Fi products, a high yield is paid to the investor every day to generate passive income:<ul><li>Ark Vault - Up to 2% daily paid in ARKFI tokens</li><li>Foundation - A variable high APY paid in BUSD</li><li>Legacy NFT - A variable high APY paid in BUSD</li></ul>'
                        },
                        {
                            id: '1e',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_1e/A2_E_A_1e_10F.json',
                            caption: 'The Ark Fi ecosystem is scalable and additional products can be added over time to increase the liquidity of the system and utility of the ARKFI token.'
                        }
                    ]
                },
                {
                    id: '2',
                    title: 'ArkFi Token',
                    animations: [
                        {
                            id: '2a',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_2a/A2_E_A_2a_10F.json',
                            caption: 'The ARKFI token is the native asset of the Ark Fi protocol and is rewarded to investors participating in the Ark Vault.'
                        },
                        {
                            id: '2b',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_2b/A2_E_A_2b_10F.json',
                            caption: 'ARKFI tokens can only be purchased through the Ark Fi Swap page on the Dapp. Purchased tokens are automatically deposited and permanently locked in the Ark Vault.'
                        },
                        {
                            id: '2c',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_2c/A2_E_A_2c_10F.json',
                            caption: 'Once deposited, ARKFI tokens are rewarded to the investor at a rate of up to 2% of their principal balance per day.'
                        },
                        {
                            id: '2d',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_2d/A2_E_A_2d_07F.json',
                            caption: 'Available rewards can be compounded back into the principal balance to grow daily earnings or withdrawn to get a return on investment.'
                        },
                        {
                            id: '2e',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_2e/A2_E_A_2e_12F.json',
                            caption: 'By compounding available rewards, adding fresh deposits or receiving referral rewards, each Ark Vault account can be rewarded up to 80,000 ARKFI tokens.'
                        },
                        {
                            id: '2f',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_2f/A2_E_A_2f_10F.json',
                            caption: 'As the ecosystem expands, additional uses cases and utilities will be created for the ARKFI token.'
                        }
                    ]
                },
                {
                    id: '3',
                    title: 'Growth and Sustainability',
                    animations: [
                        {
                            id: '3a',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_3a/A2_E_A_3a_10F.json',
                            caption: 'Ecosystem yields are sustained by steady growth of the protocol and external revenue generation from real world investments.'
                        },
                        {
                            id: '3b',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_3b/A2_E_A_3b_10F.json',
                            caption: 'An Intelligent Liquidity Controller (ILC) helps stabilize the price of ARKFI tokens and encourage new investors to enter the protocol at any time.'
                        },
                        {
                            id: '3c',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_3c/A2_E_A_3c_10F.json',
                            caption: 'A referral reward system incentivizes community-led marketing activities both online and through word of mouth to avoid high cost traditional advertising.'
                        },
                        {
                            id: '3d',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_3d/A2_E_A_3d_10F.json',
                            caption: 'The Ark Fi mobile web application allows an easy way to share your referral code with other investors and grow your Vault account balance with referral rewards.'
                        },
                        {
                            id: '3e',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_3e/A2_E_A_3e_10F.json',
                            caption: 'Investors minting Legacy NFTs provide capital for external investment opportunities and roadmap development to ensure sustainability and future growth.'
                        }
                    ]
                },
                {
                    id: '4',
                    title: 'The Foundation',
                    animations: [
                        {
                            id: '4a',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_4a/A2_E_A_4a_17F.json',
                            caption: 'For new crypto investors, the Ark Foundation is a great introduction to liquid staking and the power of compound interest.'
                        },
                        {
                            id: '4b',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_4b/A2_E_A_4b_17F.json',
                            caption: 'Staking happens when a liquidity pair called BOND is formed by depositing either BUSD or ARKFI into the Ark Foundation.'
                        },
                        {
                            id: '4c',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_4c/A2_E_A_4c_17F.json',
                            caption: 'The Ark Foundation acts like a high yield savings account, paying upwards of 100% APY in BUSD yield that can be compounded back into your BOND balance.'
                        },
                        {
                            id: '4d',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_4d/A2_E_A_4d_17F.json',
                            caption: 'A Foundation deposit is liquid and can be removed at any time. There is a 10% tax in and out to encourage holding a balance in your account.'
                        },
                        {
                            id: '4e',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_4e/A2_E_A_4e_17F.json',
                            caption: 'The price of BOND can increase or decrease with the market price of ARKFI, however, because half the value is comprised of BUSD, the price is less volatile.'
                        },
                        {
                            id: '4f',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_4f/A2_E_A_4f_21F.json',
                            caption: "By holding a BOND balance with a value of at least 250 BUSD, you'll qualify for the daily spARK Rewards drawing and Level 1 referral rewards."
                        }
                    ]
                },
                {
                    id: '5',
                    title: 'Legacy NFTs',
                    animations: [
                        {
                            id: '5a',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_5a/A2_E_A_5a_21F.json',
                            caption: 'The Legacy NFT is an ownership protocol that unlocks many benefits across the Ark Fi ecosystem and pays holders a daily BUSD dividend.'
                        },
                        {
                            id: '5b',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_5b/A2_E_A_5b_21F.json',
                            caption: 'With fractional ownership, a portion of platform fees flow back to the Legacy NFT rewards pool and maintain the daily BUSD rewards.'
                        },
                        {
                            id: '5c',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_5c/A2_E_A_5c_21F.json',
                            caption: 'Legacy NFT minters fund external investments, ecosystem expansion and partnerships that bring revenue back to the protocol.'
                        },
                        {
                            id: '5d',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_5d/A2_E_A_5d_21F.json',
                            caption: 'Investors who mint a Legacy NFT have the benefit of compounding Vault rewards more frequently to increase their daily ARKFI rewards.'
                        },
                        {
                            id: '5e',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_5e/A2_E_A_5e_21F.json',
                            caption: 'Legacy NFT holders are members of the Ark Fi DAO which provides a governance and voting layer to determine the use of investor funds.'
                        },
                        {
                            id: '5f',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_5f/A2_E_A_5f_21F.json',
                            caption: 'As the ecosystem expands, Legacy NFT holders will be the first in line to take advantage of new products and presale opportunities.'
                        }
                    ]
                },
                {
                    id: '6',
                    title: 'Affiliate Program',
                    animations: [
                        {
                            id: '6a',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_6a/A2_E_A_6a_21F.json',
                            caption: 'Referral marketing is used by some of the biggest brands in the world including Amazon, Uber, Airbnb, Pay Pal and Coinbase.'
                        },
                        {
                            id: '6b',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_6b/A2_E_A_6b_25F.json',
                            caption: 'Ark Fi rewards a qualified affiliate, known as a "Syndicate Leader", when a referral makes an investment into the Ark Vault.'
                        },
                        {
                            id: '6c',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_6c/A2_E_A_6b_27F.json',
                            caption: 'There are two types of referral rewards: 1) 5% direct rewards paid on all deposits. 2) 5% indirect rewards paid on compounds.'
                        },
                        {
                            id: '6d',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_6d/A2_E_A_6d_27F.json',
                            caption: 'All referral rewards are paid in ARKFI tokens and added to the principal balance of the Syndicate Leader`s Ark Vault account.'
                        },
                        {
                            id: '6e',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_6e/A2_E_A_6e_27F.json',
                            caption: 'The 5% deposit reward is paid when any direct referral (level 1) makes a deposit into the Ark Vault. Direct referrals are unlimited.'
                        },
                        {
                            id: '6f',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_6f/A2_E_A_6f_27F.json',
                            caption: 'To qualify for the 5% deposit rewards, you must hold at least a Silver Legacy NFT or a balance of $250 worth of BOND in the Foundation.'
                        },
                        {
                            id: '6g',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_6g/A2_E_A_6g_32F.json',
                            caption: 'The 5% compound reward is paid upline 15 levels, moving up one level for each compound and then returning when 15 levels are reached.'
                        },
                        {
                            id: '6h',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_6h/A2_E_A_6f_27F.json',
                            caption: 'To qualify for the 5% compound rewards from your first level, you must hold at least a Silver Legacy NFT or a balance of $250 worth of BOND in the Foundation.'
                        },
                        {
                            id: '6i',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_6i/A2_E_A_6i_32F.json',
                            caption: 'To qualify for compound rewards on deeper levels (up to 15), additional referral levels can be captured with a Legacy NFT or higher BOND balances.'
                        },
                        {
                            id: '6j',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_6j/A2_E_A_6j_32F.json',
                            caption: 'Ark Fi has automated the entire onboarding process. Simply share your referral link to your customized landing page with friends and family.'
                        }
                    ]
                },
                {
                    id: '7',
                    title: 'spARK Prize',
                    animations: [
                        {
                            id: '7a',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_7a/A2_E_A_7a_32F.json',
                            caption: 'The spARK Prize is a daily drawing that can supercharge your Ark Vault earnings and help maintain a 2% daily ROI.'
                        },
                        {
                            id: '7b',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_7b/A2_E_A_7b_32F.json',
                            caption: 'When a 5% compound reward isn`t captured by a qualifying upline wallet, it is sent to the Guardian Wallet.'
                        },
                        {
                            id: '7c',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_7c/A2_E_A_7c_32F.json',
                            caption: 'Every 24 hours, 50% of the compound rewards sent to the Guardian Wallet are added to the spARK Prize pool.'
                        },
                        {
                            id: '7d',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_7d/A2_E_A_7d_32F.json',
                            caption: 'Winners are drawn daily using the Ark Verifiable Random Function (VRF), a provably fair random number generator.'
                        },
                        {
                            id: '7e',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_7e/A2_E_A_7e_32F.json',
                            caption: 'The number of daily winners varies based on the size of the spARK Prize pool and a qualifying wallet can win more than once.'
                        },
                        {
                            id: '7f',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_7f/A2_E_A_6f_27F.json',
                            caption: 'To qualify for the drawing, a wallet must have an active Ark Vault account with at least $250 worth of BOND or a Legacy NFT.'
                        },
                        {
                            id: '7g',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_7g/A2_E_A_7a_32F.json',
                            caption: 'ARKFI token winnings are instantly added to the winner`s Ark Vault as a “referral reward", positively impacting NDV.'
                        },
                        {
                            id: '7h',
                            url: 'https://ark-mobile.scryptoking.com/api/explainers/A2_E_A_7h/A2_E_A_7h_32F.json',
                            caption: 'spARK rewards are an exciting event for the community and provide a big incentive to stake BOND or purchase a Legacy NFT.'
                        }
                    ]
                }
            ],
            selectedBoard: [],
            buyReferrer: ''
        }
    },
    getters: {
        selectedBoard(state) {
            return state.selectedBoard
        },
        qrModalOpen(state) {
            return state.qrModalOpen
        },
        buyReferrer(state) {
            return state.buyReferrer
        }
    },
    mutations: {
        setSelectedBoard(state, i) {
            state.selectedBoard = state.boardVideos[i]
        },
        setQrModalOpen(state, val) {
            state.qrModalOpen = val
        },
        setBuyReferrer(state, val) {
            state.buyReferrer = val
        }
    },
    actions: {

    }
}