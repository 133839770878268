import { createStore } from 'vuex'
// main
import { moduleMain } from './modules/main/'
// home
import { moduleHome } from './modules/home/'
// account
import { moduleAccount } from './modules/account/'
// legacy
import { moduleLegacy } from './modules/legacy/'
// swap
import { moduleSwap } from './modules/swap/'
// vault
import { moduleVault } from './modules/vault/'
// menu
import { moduleMenu } from './modules/menu/'
// busd
import { moduleWeb3Busd } from './modules/web3/busd/'
// web 3 main
import { moduleWeb3Main } from './modules/web3/main/'
// web3 vault
import { moduleWeb3Vault } from './modules/web3/vault/'
// web3 foundation
import { moduleWeb3Foundation } from './modules/web3/foundation/'
// web3 legacy
import { moduleWeb3Legacy } from './modules/web3/legacy/'
// web3 pair
import { moduleWeb3Pair } from './modules/web3/pair/'
// web3 swap
import { moduleWeb3Swap } from './modules/web3/swap/'
// web3 syndicate
import { moduleWeb3Syndicate } from './modules/web3/syndicate/'
// web3 token
import { moduleWeb3Token } from './modules/web3/token/'
// store
export const store = createStore({
  strict: false, //process.env.NODE_ENV !== 'production',
  modules: {
      // BASE
      // main
      moduleMain: moduleMain,
      // home
      moduleHome: moduleHome,
      // account
      moduleAccount: moduleAccount,
      // BUSD
      moduleWeb3Busd: moduleWeb3Busd,
      // legacy
      moduleLegacy: moduleLegacy,
      // swap
      moduleSwap: moduleSwap,
      // vault
      moduleVault: moduleVault,
      // menu
      moduleMenu: moduleMenu,
      // WEB 3
      // main
      moduleWeb3Main: moduleWeb3Main,
      // vault
      moduleWeb3Vault: moduleWeb3Vault,
      // fundation
      moduleWeb3Foundation: moduleWeb3Foundation,
      // legacy
      moduleWeb3Legacy: moduleWeb3Legacy,
      // pair
      moduleWeb3Pair: moduleWeb3Pair,
      // swap
      moduleWeb3Swap: moduleWeb3Swap,
      // syndicate
      moduleWeb3Syndicate: moduleWeb3Syndicate,
      // token
      moduleWeb3Token: moduleWeb3Token
  }
})