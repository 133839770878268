// import axios from 'axios'
export const moduleAccount = {
    namespaced: true,
    state () {
        return {
            address: null,
            bnbBalance: 0,
            busdBalance: 0,
            arkBalance: 0,
            test: 0,
            hasAccountVault: false
        }
    },
    getters: {
        address(state) {
            return state.address
        },
        bnbBalance(state) {
            return state.bnbBalance
        },
        hasAccountVault(state) {
            return state.hasAccountVault
        }
    },
    mutations: {
        setAddress(state, val) {
            state.address = val
        },
        setBNBBalance(state, val) {
            state.bnbBalance = val
        },
        setTest(state, val) {
            state.test = val
        },
        setHasAccountVault(state, val) {
            state.hasAccountVault = val
        }
    },
    actions: {
        async GetBNBBalance({commit}, { web3, address }) {
            commit('setTest', 0)
            try {
                var _val = await web3.eth.getBalance(address);
                _val = web3.utils.fromWei(_val);
                // bnbBalance = Math.floor(Number(_val));
                // bnbBalance2 = Math.floor(Number(_val) * 1000) / 1000;
                return Number(_val) // .toFixed(dec);
            }
            catch {
                return 0;
            }
        }
    }
}