let autoAlloc = {
    // contract
    ca: "0x00008C683f56180dF6574B928A17E09A44B60000",
    // ABI
    abi: [
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "address", "name": "investor", "type": "address" },
                { "indexed": false, "internalType": "uint256", "name": "withdrawPercent", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "compoundPercent", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "airdropPercent", "type": "uint256" },
                { "indexed": false, "internalType": "bool", "name": "autoSell", "type": "bool" },
                { "indexed": false, "internalType": "bool", "name": "autoDeposit", "type": "bool" },
                { "indexed": false, "internalType": "bool", "name": "autoBond", "type": "bool" }
            ],
            "name": "ChangedAutoAllocation",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "address", "name": "investor", "type": "address" },
                { "indexed": false, "internalType": "uint256", "name": "duration", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "paidUntil", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "paidGas", "type": "uint256" }
            ],
            "name": "ExtendedAutoAllocation",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "address", "name": "investor", "type": "address" },
                { "indexed": false, "internalType": "uint256", "name": "withdrawPercent", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "compoundPercent", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "airdropPercent", "type": "uint256" },
                { "indexed": false, "internalType": "bool", "name": "autoSell", "type": "bool" },
                { "indexed": false, "internalType": "bool", "name": "autoDeposit", "type": "bool" },
                { "indexed": false, "internalType": "bool", "name": "autoBond", "type": "bool" },
                { "indexed": false, "internalType": "uint256", "name": "duration", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "paidUntil", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "paidGas", "type": "uint256" }
            ],
            "name": "RegisteredForAutoAllocation",
            "type": "event"
        },
        { "inputs": [], "name": "SERVER_WALLET", "outputs": [{ "internalType": "address payable", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" },
        {
            "inputs": [
                { "internalType": "uint256", "name": "withdrawPercent", "type": "uint256" },
                { "internalType": "uint256", "name": "compoundPercent", "type": "uint256" },
                { "internalType": "uint256", "name": "airdropPercent", "type": "uint256" },
                { "internalType": "bool", "name": "autoSell", "type": "bool" },
                { "internalType": "bool", "name": "autoDeposit", "type": "bool" },
                { "internalType": "bool", "name": "autoBond", "type": "bool" }
            ],
            "name": "changeAutoAllocation",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        { "inputs": [{ "internalType": "uint256", "name": "duration", "type": "uint256" }], "name": "extendAutoAllocation", "outputs": [], "stateMutability": "payable", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "paidUntil", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "pricePerAction", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        {
            "inputs": [
                { "internalType": "uint256", "name": "withdrawPercent", "type": "uint256" },
                { "internalType": "uint256", "name": "compoundPercent", "type": "uint256" },
                { "internalType": "uint256", "name": "airdropPercent", "type": "uint256" },
                { "internalType": "bool", "name": "autoSell", "type": "bool" },
                { "internalType": "bool", "name": "autoDeposit", "type": "bool" },
                { "internalType": "bool", "name": "autoBond", "type": "bool" },
                { "internalType": "uint256", "name": "duration", "type": "uint256" }
            ],
            "name": "registerForAutoAllocation",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
        },
        { "inputs": [{ "internalType": "uint256", "name": "price", "type": "uint256" }], "name": "setPriceForAction", "outputs": [], "stateMutability": "nonpayable", "type": "function" }
    ]              
}
export { autoAlloc }
