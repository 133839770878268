import { bond } from './contract/index'
export const moduleWeb3Foundation = {
    namespaced: true,
    state () {
        return {
            bondSpecs: bond,
            contractBscBond: null,
            contractBond: null,
            totalRewardsPool: 0,
            totalBondStaked: 0,
            totalValBondStaked: 0,
            bondPrice: 0,
            bondValue: 0,
            yourBonds: 0,
            bondRewards: 0
        }
    },
    getters: {
        totalRewardsPool(state) {
            return state.totalRewardsPool
        },
        totalBondStaked(state) {
            return state.totalBondStaked
        },
        totalValBondStaked(state) {
            return state.totalValBondStaked
        },
        bondPrice(state) {
            return state.bondPrice
        },
        bondValue(state) {
            return state.bondValue
        },
        yourBonds(state) {
            return state.yourBonds
        },
        bondRewards(state) {
            return state.bondRewards
        }
    },
    mutations: {
        Init_Contracts(state, {web3, web3bsc }) {
            state.contractBscBond = new web3bsc.eth.Contract(state.bondSpecs.abi, state.bondSpecs.ca)
            state.contractBond = new web3.eth.Contract(state.bondSpecs.abi, state.bondSpecs.ca)
        },
        setTotalRewardsPool(state, val) {
            state.totalRewardsPool = val
        },
        setTotalBondStaked(state, val) {
            state.totalBondStaked = val
        },
        setTotalValBondStaked(state, val) {
            state.totalValBondStaked = val
        },
        setBondPrice(state, val) {
            state.bondPrice = val
        },
        setBondValue(state, val) {
            state.bondValue = val
        },
        setYourBonds(state, val) {
            state.yourBonds = val
        },
        setBondRewards(state, val) {
            state.bondRewards = val
        }
    },
    actions: {
        async GetClaimedRewards_Bond({ state }, { web3, address }) {
            try {
                var _val = await state.contractBscBond.methods.claimedRewards(address).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        async GetRewardsPool_Bond({ state }, { web3 }) {
            try {
                var _val = await state.contractBscBond.methods.rewardsPool().call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(2);
            }
            catch(error) {
                console.log(error.message)
                return 0;
            }
        },
        async GetAvailableRewards_Bond({ state }, { web3, address }) {
            try {
                var _val = await state.contractBscBond.methods.checkAvailableRewards(address).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val).toFixed(2);
            }
            catch {
                return 0.00;
            }
        },
        async GetShares_Bond({ state }, { web3, address }) {
            try {
                var _val = await state.contractBscBond.methods.shares(address).call();
                _val = web3.utils.fromWei(_val);
                // bondBalance = Math.floor(Number(_val) * 1000) / 1000;
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        },
        
        async GetTotalShares_Bond({ state }, { web3 }) {
            try {
                var _val = await state.contractBscBond.methods.totalShares().call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(2);
            }
            catch {
                return 0;
            }
        }
    }
}