import { pair } from './contract/index'
export const moduleWeb3Pair = {
    namespaced: true,
    state () {
        return {
            // contract settings
            pairSpecs: pair,
            contractBscPair: null,
            contractPair: null,
            // contract vars
            reserveBUSD_Pair: 0,
            reserveARK_Pair: 0,
            totalSupplyPair: 0
        }
    },
    getters: {
        reserveBUSD_Pair(state) {
            return state.reserveBUSD_Pair
        },
        reserveARK_Pair(state) {
            return state.reserveARK_Pair
        },
        totalSupplyPair(state) {
            return state.totalSupplyPair
        }
    },
    mutations: {
        Init_Contracts(state, {web3, web3bsc }) {
            state.contractBscPair = new web3bsc.eth.Contract(state.pairSpecs.abi, state.pairSpecs.ca)
            state.contractPair = new web3.eth.Contract(state.pairSpecs.abi, state.pairSpecs.ca)
        },
        setReserveBUSD_Pair(state, val) {
            state.reserveBUSD_Pair = val
        },
        setReserveARK_Pair(state, val) {
            state.reserveARK_Pair = val
        },
        setTotalSupplyPair(state, val) {
            state.totalSupplyPair = val
        }
    },
    actions: {
        async GetBalance_Pair({ state }, { web3, address }) {
            try {
                var _val = await state.contractBscPair.methods.balanceOf(address).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val);
            }
            catch {
                return 0;
            }
        },
        async GetReserve_Pair({ state }) {
            try {
                var _val = await state.contractBscPair.methods.getReserves().call();
                return _val;        
            }
            catch {
                return 0;
            }
        },
        async GetReserveBUSD_Pair({ state }, { web3 }) {
            try {
                var _val = await state.contractBscPair.methods.getReserves().call();
                _val = Number(web3.utils.fromWei(_val._reserve1)) // .toFixed(0);
                return _val;
            }
            catch {
                return 0;
            }
        },
        async GetReserveARK_Pair({ state }, { web3 }) {
            try {
                var _val = await state.contractBscPair.methods.getReserves().call();
                _val = Number(web3.utils.fromWei(_val._reserve0)) // .toFixed(0);
                return _val;
            }
            catch {
                return 0;
            }
        },
        async GetTotalSupply_Pair({ state }, { web3 }) {
            try {
                var _val = await state.contractBscPair.methods.totalSupply().call();
                _val = web3.utils.fromWei(_val);
                return Number(_val);
            }
            catch {
                return 0;
            }
        }
    }
}