let legacy = {
    // contract
    ca: "0x2222223B05B5842c918a868928F57cD3A0332222",
    // ABI
    abi: [
        { "inputs": [], "stateMutability": "nonpayable", "type": "constructor" },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": true, "internalType": "address", "name": "owner", "type": "address" },
                { "indexed": true, "internalType": "address", "name": "approved", "type": "address" },
                { "indexed": true, "internalType": "uint256", "name": "tokenId", "type": "uint256" }
            ],
            "name": "Approval",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": true, "internalType": "address", "name": "owner", "type": "address" },
                { "indexed": true, "internalType": "address", "name": "operator", "type": "address" },
                { "indexed": false, "internalType": "bool", "name": "approved", "type": "bool" }
            ],
            "name": "ApprovalForAll",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "address", "name": "arkWallet", "type": "address" },
                { "indexed": false, "internalType": "bool", "name": "status", "type": "bool" }
            ],
            "name": "ArkWalletSet",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "uint256", "name": "oldBalance", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "rewardsPool", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "unclaimedRewards", "type": "uint256" }
            ],
            "name": "MigrationFinished",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": true, "internalType": "address", "name": "user", "type": "address" },
                { "indexed": true, "internalType": "uint256", "name": "tokenId", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "oldLevel", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "newLevel", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "priceOfLevelUp", "type": "uint256" }
            ],
            "name": "NftLevelledUp",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": true, "internalType": "address", "name": "user", "type": "address" },
                { "indexed": true, "internalType": "uint256", "name": "tokenId", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "level", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "price", "type": "uint256" }
            ],
            "name": "NftMinted",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "address", "name": "seller", "type": "address" },
                { "indexed": false, "internalType": "uint256", "name": "id", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "price", "type": "uint256" }
            ],
            "name": "NftOffered",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "address", "name": "seller", "type": "address" },
                { "indexed": false, "internalType": "address", "name": "buyer", "type": "address" },
                { "indexed": false, "internalType": "uint256", "name": "id", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "price", "type": "uint256" }
            ],
            "name": "NftSold",
            "type": "event"
        },
        { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "uint256", "name": "rewardsToBeAdded", "type": "uint256" }], "name": "RewardsAdded", "type": "event" },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "address", "name": "investor", "type": "address" },
                { "indexed": false, "internalType": "uint256", "name": "claimableNow", "type": "uint256" }
            ],
            "name": "RewardsClaimed",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "uint256", "name": "rewardsToBeAdded", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "rewardsPerShareToday", "type": "uint256" }
            ],
            "name": "RewardsDistributed",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": true, "internalType": "address", "name": "from", "type": "address" },
                { "indexed": true, "internalType": "address", "name": "to", "type": "address" },
                { "indexed": true, "internalType": "uint256", "name": "tokenId", "type": "uint256" }
            ],
            "name": "Transfer",
            "type": "event"
        },
        { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "address", "name": "vaultAddress", "type": "address" }], "name": "VaultSet", "type": "event" },
        {
            "anonymous": false,
            "inputs": [
                {
                    "components": [
                        { "internalType": "uint256", "name": "yes", "type": "uint256" },
                        { "internalType": "uint256", "name": "no", "type": "uint256" },
                        { "internalType": "string", "name": "topic", "type": "string" },
                        { "internalType": "uint256", "name": "endTime", "type": "uint256" }
                    ],
                    "indexed": false,
                    "internalType": "struct ARK_LEGACY.Vote",
                    "name": "newVote",
                    "type": "tuple"
                }
            ],
            "name": "VoteNowIfYouCare",
            "type": "event"
        },
        { "inputs": [], "name": "BUSD", "outputs": [{ "internalType": "contract IBEP20", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "OLD", "outputs": [{ "internalType": "contract ILEGACYOLD", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "busdAmount", "type": "uint256" }], "name": "addToRewards", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "id", "type": "uint256" }], "name": "adminUnlockNft", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [], "name": "airdropEveryone", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        {
            "inputs": [
                { "internalType": "address", "name": "to", "type": "address" },
                { "internalType": "uint256", "name": "tokenId", "type": "uint256" }
            ],
            "name": "approve",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        { "inputs": [{ "internalType": "address", "name": "owner", "type": "address" }], "name": "balanceOf", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "id", "type": "uint256" }], "name": "buy", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [], "name": "claimRewards", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "investor", "type": "address" }], "name": "claimRewardsFor", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "name": "claimedRewards", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        {
            "inputs": [
                { "internalType": "uint256", "name": "level", "type": "uint256" },
                { "internalType": "uint256", "name": "price", "type": "uint256" },
                { "internalType": "uint256", "name": "maxSupply", "type": "uint256" },
                { "internalType": "uint256", "name": "cwr", "type": "uint256" },
                { "internalType": "uint256", "name": "_votes", "type": "uint256" },
                { "internalType": "uint256", "name": "levels", "type": "uint256" },
                { "internalType": "uint256", "name": "share", "type": "uint256" }
            ],
            "name": "createNewLevel",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "string", "name": "whatToVoteOn", "type": "string" },
                { "internalType": "uint256", "name": "cutOffTime", "type": "uint256" }
            ],
            "name": "createNewVote",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        { "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "name": "cwrOfLevel", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "dailyRewardPercent", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        {
            "inputs": [
                { "internalType": "uint256", "name": "", "type": "uint256" },
                { "internalType": "uint256", "name": "", "type": "uint256" }
            ],
            "name": "decisionOnVote",
            "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
            "stateMutability": "view",
            "type": "function"
        },
        { "inputs": [], "name": "distributeRewards", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [], "name": "distributeRewardsManuallyCEO", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "name": "excluded", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "getAllIdsForSale", "outputs": [{ "internalType": "uint256[]", "name": "", "type": "uint256[]" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "tokenId", "type": "uint256" }], "name": "getApproved", "outputs": [{ "internalType": "address", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" },
        {
            "inputs": [{ "internalType": "address", "name": "investor", "type": "address" }],
            "name": "getClaimableRewards",
            "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
            "stateMutability": "view",
            "type": "function"
        },
        { "inputs": [{ "internalType": "address", "name": "investor", "type": "address" }], "name": "getCwr", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "investor", "type": "address" }], "name": "getLevels", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        {
            "inputs": [{ "internalType": "address", "name": "investor", "type": "address" }],
            "name": "getLifetimeRewards",
            "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
            "stateMutability": "view",
            "type": "function"
        },
        { "inputs": [{ "internalType": "uint256", "name": "id", "type": "uint256" }], "name": "getPriceForLevelUp", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "investor", "type": "address" }], "name": "getShares", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "name": "idForSale", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "view", "type": "function" },
        {
            "inputs": [
                { "internalType": "uint256", "name": "level", "type": "uint256" },
                { "internalType": "uint256", "name": "howMany", "type": "uint256" }
            ],
            "name": "increaseLimitOfNFTs",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "address", "name": "owner", "type": "address" },
                { "internalType": "address", "name": "operator", "type": "address" }
            ],
            "name": "isApprovedForAll",
            "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
            "stateMutability": "view",
            "type": "function"
        },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "isArk", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "lastDistribution", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "name": "levelOfNft", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "id", "type": "uint256" }], "name": "levelUp", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "name": "levelsOfLevel", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "lockNft", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "name": "locked", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "maxCwrWithoutNft", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "name": "maxNftsOfLevel", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "level", "type": "uint256" }], "name": "mint", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        {
            "inputs": [
                { "internalType": "address", "name": "to", "type": "address" },
                { "internalType": "uint256", "name": "level", "type": "uint256" }
            ],
            "name": "mintToWallet",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "address", "name": "to", "type": "address" },
                { "internalType": "uint256", "name": "level", "type": "uint256" }
            ],
            "name": "mintToWalletPaid",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "uint256", "name": "level", "type": "uint256" },
                { "internalType": "uint256", "name": "price", "type": "uint256" },
                { "internalType": "uint256", "name": "maxSupply", "type": "uint256" },
                { "internalType": "uint256", "name": "cwr", "type": "uint256" },
                { "internalType": "uint256", "name": "_votes", "type": "uint256" },
                { "internalType": "uint256", "name": "levels", "type": "uint256" }
            ],
            "name": "modifyLevel",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        { "inputs": [], "name": "name", "outputs": [{ "internalType": "string", "name": "", "type": "string" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "name": "nftPriceOfLevel", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "name": "nftsForSale", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "name": "nftsLeftOfLevel", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "openingHour", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "tokenId", "type": "uint256" }], "name": "ownerOf", "outputs": [{ "internalType": "address", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "name": "priceOfId", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "name": "pricePaidForId", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "tokenToRescue", "type": "address" }], "name": "rescueAnyToken", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [], "name": "resetDistributionTimer", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [], "name": "rewardsPercent", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "rewardsPool", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        {
            "inputs": [
                { "internalType": "address", "name": "from", "type": "address" },
                { "internalType": "address", "name": "to", "type": "address" },
                { "internalType": "uint256", "name": "tokenId", "type": "uint256" }
            ],
            "name": "safeTransferFrom",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "address", "name": "from", "type": "address" },
                { "internalType": "address", "name": "to", "type": "address" },
                { "internalType": "uint256", "name": "tokenId", "type": "uint256" },
                { "internalType": "bytes", "name": "data", "type": "bytes" }
            ],
            "name": "safeTransferFrom",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "uint256", "name": "id", "type": "uint256" },
                { "internalType": "uint256", "name": "price", "type": "uint256" }
            ],
            "name": "sell",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "address", "name": "operator", "type": "address" },
                { "internalType": "bool", "name": "approved", "type": "bool" }
            ],
            "name": "setApprovalForAll",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "address", "name": "arkWallet", "type": "address" },
                { "internalType": "bool", "name": "status", "type": "bool" }
            ],
            "name": "setArkWallet",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        { "inputs": [{ "internalType": "string", "name": "uri", "type": "string" }], "name": "setBaseUri", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [{ "internalType": "string", "name": "ext", "type": "string" }], "name": "setFileExtension", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "percent", "type": "uint256" }], "name": "setRewardPercentagePerDay", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "percent", "type": "uint256" }], "name": "setRewardPercentagePerMint", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "vaultAddress", "type": "address" }], "name": "setVaultAddress", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "name": "shares", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "name": "sharesOfLevel", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "bytes4", "name": "interfaceId", "type": "bytes4" }], "name": "supportsInterface", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "symbol", "outputs": [{ "internalType": "string", "name": "", "type": "string" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "index", "type": "uint256" }], "name": "tokenByIndex", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        {
            "inputs": [
                { "internalType": "address", "name": "owner", "type": "address" },
                { "internalType": "uint256", "name": "index", "type": "uint256" }
            ],
            "name": "tokenOfOwnerByIndex",
            "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
            "stateMutability": "view",
            "type": "function"
        },
        { "inputs": [{ "internalType": "uint256", "name": "tokenId", "type": "uint256" }], "name": "tokenURI", "outputs": [{ "internalType": "string", "name": "", "type": "string" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "totalContributions", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "totalRewardsPerShare", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "totalShares", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "totalSupply", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "name": "totalSupplyOfLevel", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        {
            "inputs": [
                { "internalType": "address", "name": "from", "type": "address" },
                { "internalType": "address", "name": "to", "type": "address" },
                { "internalType": "uint256", "name": "tokenId", "type": "uint256" }
            ],
            "name": "transferFrom",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        { "inputs": [], "name": "unlockNft", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [], "name": "vault", "outputs": [{ "internalType": "contract IVAULT", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" },
        {
            "inputs": [
                { "internalType": "uint256", "name": "index", "type": "uint256" },
                { "internalType": "bool", "name": "yourVote", "type": "bool" }
            ],
            "name": "vote",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "uint256", "name": "", "type": "uint256" },
                { "internalType": "uint256", "name": "", "type": "uint256" }
            ],
            "name": "voteCast",
            "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
            "name": "votes",
            "outputs": [
                { "internalType": "uint256", "name": "yes", "type": "uint256" },
                { "internalType": "uint256", "name": "no", "type": "uint256" },
                { "internalType": "string", "name": "topic", "type": "string" },
                { "internalType": "uint256", "name": "endTime", "type": "uint256" }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        { "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "name": "votesOfLevel", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" }
    ]    
}
export { legacy }
