let vault = {
    // contract
    ca: "0x13fd16538ff8b3aea324bc4d2863eb9ea78e1691",
    // ABI
    abi: [
        { "inputs": [{ "internalType": "address", "name": "investor", "type": "address" }], "name": "addSparkPlayer", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        {
            "inputs": [
                { "internalType": "address[]", "name": "airdroppees", "type": "address[]" },
                { "internalType": "uint256[]", "name": "amounts", "type": "uint256[]" }
            ],
            "name": "airdrop",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "address", "name": "token", "type": "address" },
                { "internalType": "address", "name": "approvee", "type": "address" }
            ],
            "name": "approveNewContract",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        { "inputs": [{ "internalType": "address", "name": "investor", "type": "address" }], "name": "migrateAccount", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [{ "internalType": "address[]", "name": "investors", "type": "address[]" }], "name": "migrateAccounts", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        {
            "inputs": [
                { "internalType": "address", "name": "investor", "type": "address" },
                { "internalType": "address", "name": "newInvestor", "type": "address" }
            ],
            "name": "migrateCompromisedAccount",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "address[]", "name": "investors", "type": "address[]" },
                { "internalType": "address", "name": "oldReferrer", "type": "address" }
            ],
            "name": "regenerateUpline",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "address", "name": "tokenToRescue", "type": "address" },
                { "internalType": "uint256", "name": "percent", "type": "uint256" }
            ],
            "name": "rescueAnyToken",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        { "inputs": [], "name": "rescueBnb", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        {
            "inputs": [
                { "internalType": "address", "name": "arkWallet", "type": "address" },
                { "internalType": "bool", "name": "status", "type": "bool" }
            ],
            "name": "setArkWallet",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        { "inputs": [{ "internalType": "address", "name": "bondAddress", "type": "address" }], "name": "setBondAddress", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        {
            "inputs": [
                { "internalType": "uint256", "name": "level", "type": "uint256" },
                { "internalType": "uint256", "name": "price", "type": "uint256" }
            ],
            "name": "setBondLevelPrice",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "uint256", "name": "buyPercent", "type": "uint256" },
                { "internalType": "uint256", "name": "buyReferralPercent", "type": "uint256" },
                { "internalType": "uint256", "name": "buySwapTax", "type": "uint256" }
            ],
            "name": "setBuyTaxes",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "uint256", "name": "percentTax", "type": "uint256" },
                { "internalType": "uint256", "name": "percentReferral", "type": "uint256" }
            ],
            "name": "setDepositTaxes",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        { "inputs": [{ "internalType": "address", "name": "legacyAddress", "type": "address" }], "name": "setLegacyAddress", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        {
            "inputs": [
                { "internalType": "uint256", "name": "minAmount", "type": "uint256" },
                { "internalType": "uint256", "name": "maxAmount", "type": "uint256" }
            ],
            "name": "setMaxAndMinDeposit",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "address", "name": "investor", "type": "address" },
                { "internalType": "bool", "name": "status", "type": "bool" }
            ],
            "name": "setPenalized",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "uint256", "name": "penalizedPerMille", "type": "uint256" },
                { "internalType": "uint256", "name": "normalPerMille", "type": "uint256" },
                { "internalType": "uint256", "name": "reducedPerMille", "type": "uint256" }
            ],
            "name": "setRoi",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        { "inputs": [{ "internalType": "address", "name": "swapAddress", "type": "address" }], "name": "setSwapAddress", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        {
            "inputs": [
                { "internalType": "uint256", "name": "_nonce", "type": "uint256" },
                { "internalType": "uint256[]", "name": "randomNumbers", "type": "uint256[]" }
            ],
            "name": "supplyRandomness",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "uint256", "name": "withdrawPercent", "type": "uint256" },
                { "internalType": "uint256", "name": "compoundPercent", "type": "uint256" },
                { "internalType": "uint256", "name": "airdropPercent", "type": "uint256" },
                { "internalType": "bool", "name": "autoSell", "type": "bool" },
                { "internalType": "bool", "name": "autoDeposit", "type": "bool" },
                { "internalType": "bool", "name": "autoBond", "type": "bool" }
            ],
            "name": "takeAction",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "address", "name": "investor", "type": "address" },
                { "internalType": "uint256", "name": "withdrawPercent", "type": "uint256" },
                { "internalType": "uint256", "name": "compoundPercent", "type": "uint256" },
                { "internalType": "uint256", "name": "airdropPercent", "type": "uint256" },
                { "internalType": "bool", "name": "autoSell", "type": "bool" },
                { "internalType": "bool", "name": "autoDeposit", "type": "bool" },
                { "internalType": "bool", "name": "autoBond", "type": "bool" }
            ],
            "name": "takeAutomatedAction",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        { "inputs": [{ "internalType": "address", "name": "investor", "type": "address" }], "name": "terminateAccount", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [], "stateMutability": "nonpayable", "type": "constructor" },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "address[]", "name": "airdroppees", "type": "address[]" },
                { "indexed": false, "internalType": "uint256[]", "name": "amounts", "type": "uint256[]" }
            ],
            "name": "AirDropsSent",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "address", "name": "arkWallet", "type": "address" },
                { "indexed": false, "internalType": "bool", "name": "status", "type": "bool" }
            ],
            "name": "ArkWalletSet",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "address", "name": "investor", "type": "address" },
                { "indexed": false, "internalType": "uint256", "name": "withdrawPercent", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "compoundPercent", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "airdropPercent", "type": "uint256" },
                { "indexed": false, "internalType": "bool", "name": "autoSell", "type": "bool" },
                { "indexed": false, "internalType": "bool", "name": "autoDeposit", "type": "bool" },
                { "indexed": false, "internalType": "bool", "name": "autoBond", "type": "bool" }
            ],
            "name": "AutomatedActionTaken",
            "type": "event"
        },
        { "anonymous": false, "inputs": [], "name": "BnbRescued", "type": "event" },
        {
            "inputs": [
                { "internalType": "address", "name": "investor", "type": "address" },
                { "internalType": "address", "name": "newReferrer", "type": "address" }
            ],
            "name": "changeReferrerAndUpline",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "uint256", "name": "amount", "type": "uint256" },
                { "internalType": "address", "name": "referrer", "type": "address" }
            ],
            "name": "deposit",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "address", "name": "investor", "type": "address" },
                { "indexed": false, "internalType": "uint256", "name": "amount", "type": "uint256" }
            ],
            "name": "Deposit",
            "type": "event"
        },
        {
            "inputs": [
                { "internalType": "address", "name": "investor", "type": "address" },
                { "internalType": "uint256", "name": "amount", "type": "uint256" },
                { "internalType": "address", "name": "referrer", "type": "address" }
            ],
            "name": "depositFor",
            "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "address", "name": "investor", "type": "address" },
                { "internalType": "uint256", "name": "amount", "type": "uint256" },
                { "internalType": "address", "name": "referrer", "type": "address" },
                { "internalType": "bool", "name": "taxFree", "type": "bool" }
            ],
            "name": "depositTo",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "address[]", "name": "investors", "type": "address[]" },
                { "internalType": "uint256[]", "name": "amounts", "type": "uint256[]" },
                { "internalType": "address", "name": "referrer", "type": "address" },
                { "internalType": "bool", "name": "taxFree", "type": "bool" }
            ],
            "name": "depositToMany",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "address", "name": "referrer", "type": "address" },
                { "indexed": false, "internalType": "uint256", "name": "amount", "type": "uint256" }
            ],
            "name": "DirectReferralRewardsPaid",
            "type": "event"
        },
        { "inputs": [{ "internalType": "uint256", "name": "percentOfSpark", "type": "uint256" }], "name": "drawSparkWinnerWithPercent", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "address", "name": "investor", "type": "address" }], "name": "InvestorMigrated", "type": "event" },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "address", "name": "investor", "type": "address" },
                { "indexed": false, "internalType": "uint256", "name": "withdrawPercent", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "compoundPercent", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "airdropPercent", "type": "uint256" },
                { "indexed": false, "internalType": "bool", "name": "autoSell", "type": "bool" },
                { "indexed": false, "internalType": "bool", "name": "autoDeposit", "type": "bool" },
                { "indexed": false, "internalType": "bool", "name": "autoBond", "type": "bool" }
            ],
            "name": "ManualActionTaken",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "address", "name": "investor", "type": "address" },
                { "indexed": false, "internalType": "uint256", "name": "amount", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "timestamp", "type": "uint256" }
            ],
            "name": "NewAccountOpened",
            "type": "event"
        },
        { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "address", "name": "investor", "type": "address" }], "name": "RoiIncreased", "type": "event" },
        { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "address", "name": "investor", "type": "address" }], "name": "RoiReduced", "type": "event" },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "address", "name": "referrer", "type": "address" },
                { "indexed": false, "internalType": "uint256", "name": "amount", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "roundRobinPosition", "type": "uint256" }
            ],
            "name": "RoundRobinReferralRewardsPaid",
            "type": "event"
        },
        { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "address", "name": "investor", "type": "address" }], "name": "SomeoneHasReachedMaxPayout", "type": "event" },
        { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "address", "name": "investor", "type": "address" }], "name": "SomeoneIsDoneCompounding", "type": "event" },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "address", "name": "investor", "type": "address" },
                { "indexed": false, "internalType": "uint256", "name": "maxCwr", "type": "uint256" }
            ],
            "name": "SomeoneIsUsingHisNftToHyperCompound",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "address", "name": "investor", "type": "address" },
                { "indexed": false, "internalType": "address", "name": "referrer", "type": "address" }
            ],
            "name": "SomeoneJoinedTheSystem",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "address", "name": "investor", "type": "address" },
                { "indexed": false, "internalType": "uint256", "name": "totalAirdropAmount", "type": "uint256" }
            ],
            "name": "SomeoneWasFeelingGenerous",
            "type": "event"
        },
        { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "address", "name": "investor", "type": "address" }], "name": "SomeoneWasNaughtyAndWillBePunished", "type": "event" },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "uint256", "name": "amount", "type": "uint256" },
                { "indexed": false, "internalType": "address", "name": "whoWasntEligible", "type": "address" }
            ],
            "name": "SparkPotToppedUp",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "address", "name": "winner", "type": "address" },
                { "indexed": false, "internalType": "uint256", "name": "prizeMoney", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "winnerNumber", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "timestamp", "type": "uint256" }
            ],
            "name": "SparkWinnerPaid",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "address", "name": "investor", "type": "address" },
                { "indexed": false, "internalType": "uint256", "name": "rewardsAllocatedAfterTaxes", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "withdrawPercent", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "compoundPercent", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "airdropPercent", "type": "uint256" }
            ],
            "name": "ThingDone",
            "type": "event"
        },
        { "stateMutability": "payable", "type": "receive" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "accountReachedMaxPayout", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "view", "type": "function" },
        {
            "inputs": [
                { "internalType": "address", "name": "", "type": "address" },
                { "internalType": "uint256", "name": "", "type": "uint256" }
            ],
            "name": "actions",
            "outputs": [
                { "internalType": "uint256", "name": "compoundSeconds", "type": "uint256" },
                { "internalType": "uint256", "name": "withdrawSeconds", "type": "uint256" }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "address", "name": "investor", "type": "address" },
                { "internalType": "uint256", "name": "nftLevels", "type": "uint256" }
            ],
            "name": "addLevelsFromBond",
            "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
            "stateMutability": "view",
            "type": "function"
        },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "airdropBalance", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "airdropped", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "airdropsReceived", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "ARK", "outputs": [{ "internalType": "contract IBEP20", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "basicTax", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "bond", "outputs": [{ "internalType": "contract IBOND", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "name": "bondLevelPrices", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "BUSD", "outputs": [{ "internalType": "contract IBEP20", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "buyReferralTax", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "buyTax", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        {
            "inputs": [{ "internalType": "uint256", "name": "amountOfBond", "type": "uint256" }],
            "name": "calculateUsdValueOfBond",
            "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
            "stateMutability": "view",
            "type": "function"
        },
        { "inputs": [], "name": "CEO", "outputs": [{ "internalType": "address", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "investor", "type": "address" }], "name": "checkNdv", "outputs": [{ "internalType": "int256", "name": "", "type": "int256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "compounds", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "cwr", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "cwrAverageTime", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "cwrLowerLimit", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "depositReferralTax", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "deposits", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "depositTax", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "directRewards", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "doneCompounding", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "view", "type": "function" },
        {
            "inputs": [{ "internalType": "address", "name": "investor", "type": "address" }],
            "name": "getAvailableReward",
            "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
            "stateMutability": "view",
            "type": "function"
        },
        { "inputs": [{ "internalType": "address", "name": "investor", "type": "address" }], "name": "getBondValue", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        {
            "inputs": [
                { "internalType": "address", "name": "investor", "type": "address" },
                { "internalType": "uint256", "name": "timeSinceLastAction", "type": "uint256" },
                { "internalType": "uint256", "name": "withdrawPercent", "type": "uint256" },
                { "internalType": "uint256", "name": "compoundPercent", "type": "uint256" },
                { "internalType": "uint256", "name": "airdropPercent", "type": "uint256" }
            ],
            "name": "getRollingAverageCwr",
            "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
            "stateMutability": "view",
            "type": "function"
        },
        { "inputs": [{ "internalType": "address", "name": "investor", "type": "address" }], "name": "hasAccount", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "isArk", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "view", "type": "function" },
        {
            "inputs": [
                { "internalType": "address", "name": "uplineAddress", "type": "address" },
                { "internalType": "uint256", "name": "uplinePosition", "type": "uint256" }
            ],
            "name": "isEligible",
            "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
            "stateMutability": "view",
            "type": "function"
        },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "lastAction", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "legacy", "outputs": [{ "internalType": "contract ILEGACY", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "maxCwr", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "maxCwrWithoutNft", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "maxDeposit", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "maxPayoutAmount", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "maxPayoutPercentage", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "maxTax", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "minDeposit", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "MULTIPLIER", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "newDeposits", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "name": "nonceProcessed", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "oldVault", "outputs": [{ "internalType": "contract IVAULT", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "out", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "penalized", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "postTaxOut", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "principalBalance", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "name": "prizeAtNonce", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "referrerOf", "outputs": [{ "internalType": "address", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "roi", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "roiNormal", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "roiPenalized", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "roiReduced", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "roundRobinPosition", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "roundRobinRewards", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "roundRobinTax", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "sparkPlayerAdded", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "name": "sparkPlayers", "outputs": [{ "internalType": "address", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "sparkPot", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "sparkPotPercent", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "swap", "outputs": [{ "internalType": "contract ISWAP", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "swapBuyTax", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "tax", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "taxIncrease", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "taxLevelSteps", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "timeOfEntry", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "timer", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "totalAccounts", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        {
            "inputs": [{ "internalType": "address", "name": "investor", "type": "address" }],
            "name": "totalActionsOfInvestor",
            "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
            "stateMutability": "view",
            "type": "function"
        },
        { "inputs": [], "name": "totalPrizeMoneyPaid", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "totalWinners", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        {
            "inputs": [
                { "internalType": "address", "name": "", "type": "address" },
                { "internalType": "uint256", "name": "", "type": "uint256" }
            ],
            "name": "upline",
            "outputs": [{ "internalType": "address", "name": "", "type": "address" }],
            "stateMutability": "view",
            "type": "function"
        },
        { "inputs": [], "name": "VRF", "outputs": [{ "internalType": "contract ICCVRF", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "withdrawn", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" }
    ]        
}
export { vault }
