<template>
  <img src="assets/animations/home/arkline.gif" class="ark-line"/>
</template>
<script>
import {
    defineComponent
} from 'vue'
export default defineComponent({
    name: 'HomeRainbow',
    computed: {
      width: {
        get() {
          return window.innerWidth
        }
      }
    }
})
</script>
<style scoped>
.ark-line {
  margin-top: 50px;
  width: 120%;
  transform: rotateX(180deg);
  mix-blend-mode: darken;
  background-blend-mode:screen
}
.inner-div {
  margin-top: 50px;
  width: 110%; 
  margin-left: -5%;
  margin-right: -5%;
  height: 100px;  
  border-color:#4169E1 transparent transparent transparent;
  border-radius: 48%/100px 100px 0 0;
  box-shadow: 0 -20px 20px -10px #4169E1, 0 -4px 0 0 #4169E1;
  animation: mymove 6s infinite;
}

.outer-div {
  margin-top: 50px;
  width: 110%; 
  margin-left: -5%;
  margin-right: -5%;
  height: 150px;  
  border-color:#4169E1 transparent transparent transparent;
  border-radius: 40%/150px 150px 0 0;
  box-shadow: 0 -20px 20px -10px #4169E1, 0 -4px 0 0 #4169E1;
  animation: mymove 6s infinite;
}

@keyframes mymove {
  25% { box-shadow: 0 -20px 20px -10px #CFD3EC, 0 -4px 0 0 #CFD3EC; }
  50% { box-shadow: 0 -20px 20px -10px #FFE178, 0 -4px 0 0 #FFE178;; }
  75% { box-shadow: 0 -20px 20px -10px #80E0FF, 0 -4px 0 0 #80E0FF;; }
  100% { box-shadow: 0 -20px 20px -10px #4169E1, 0 -4px 0 0 #4169E1; }
}
</style>