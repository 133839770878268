import { token } from './contract/index'
import { swap } from "../swap/contract/index"
import { vault } from "../vault/contract/index"
import { pair } from "../pair/contract/index"
import axios from 'axios'
export const moduleWeb3Token = {
    namespaced: true,
    state () {
        return {
            // contract settings swap
            swapSpecs: swap,
            // contract settings vault
            vaultSpecs: vault,
            // contract settings pair
            pairSpecs: pair,
            // contract settings
            tokenSpecs: token,
            contractBscToken: null,
            contractToken: null,
            // contract vars
            totalSupply_Token: 0,
            circulatingSupply: 0,
            arkBalance: 0,
            arkApproved: false,
            arkDepositApproved: false,
            priceChartData: [],
            liquidityChartData: [],
            marketCapChartData: [],
            circulatingSupplyChartData: [],
            vaultReserves: 0
        }
    },
    getters: {
        totalSupply_Token(state) {
            return state.totalSupply_Token
        },
        circulatingSupply_Token(state) {
            return state.circulatingSupply
        },
        arkBalance(state) {
            return state.arkBalance
        },
        arkApproved(state) {
            return state.arkApproved
        },
        arkDepositApproved(state) {
            return state.arkDepositApproved
        },
        priceChartData(state) {
            return state.priceChartData
        },
        liquidityChartData(state) {
            return state.liquidityChartData
        },
        marketCapChartData(state) {
            return state.marketCapChartData
        },
        circulatingSupplyChartData(state) {
            return state.circulatingSupplyChartData
        },
        vaultReserves(state) {
            return state.vaultReserves
        }
    },
    mutations: {
        Init_Contracts(state, {web3, web3bsc }) {
            state.contractBscToken = new web3bsc.eth.Contract(state.tokenSpecs.abi, state.tokenSpecs.ca)
            state.contractToken = new web3.eth.Contract(state.tokenSpecs.abi, state.tokenSpecs.ca)
        },
        setTotalSupply_Token(state, val) {
            state.totalSupply_Token = val
        },
        setCirculatingSupply_Token(state, val) {
            state.circulatingSupply = val
        },
        setARKBalance_Token(state, val) {
            state.arkBalance = val
        },
        setARKApproved(state, val) {
            state.arkApproved = val
        },
        setArkDepositApproved(state, val) {
            state.arkDepositApproved = val
        },
        setPriceChartData(state, { val, web3 }) {
            var _arr = val.slice(0,8640)
            var _data = []
            // _arr = _arr.reverse()
            _arr.forEach((i) => {
                var liq = web3.utils.fromWei(i.liquidity_usd)
                var sup = web3.utils.fromWei(i.circulating_supply)
                var price = liq / sup
                _data.push(price)
            })
            state.priceChartData = _data
        },
        setLiquidityChartData(state, { val, web3 }) {
            var _arr = val.slice(0, 288)
            var _data = []
            _arr = _arr.reverse()
            _arr.forEach((i) => {
                var liq = web3.utils.fromWei(i.liquidity_usd)
                _data.push(Number(liq))
            })
            state.liquidityChartData = _data
        },
        setMarketCapChartData(state, { val, web3 }) {
            var _arr = val.slice(0, 288)
            var _data = []
            _arr = _arr.reverse()
            _arr.forEach((i) => {
                var liq = web3.utils.fromWei(i.liquidity_usd)
                var sup = web3.utils.fromWei(i.circulating_supply)
                var totSup = web3.utils.fromWei(i.total_supply)
                var price = liq / sup
                var mc = price * totSup
                _data.push(Number(mc))
            })
            state.marketCapChartData = _data
        },
        setCirculatingSupplyChartData(state, { val, web3 }) {
            var _arr = val.slice(0, 288)
            var _data = []
            _arr = _arr.reverse()
            _arr.forEach((i) => {
                var sup = web3.utils.fromWei(i.circulating_supply)
                _data.push(Number(sup))
            })
            state.circulatingSupplyChartData = _data
        },
        setVaultReserves(state, val) {
            state.vaultReserves = val
        }
    },
    actions: {
        async GetVaultReserves_Token({ state }, { web3}) {
            try {
                var _val = await state.contractBscToken.methods.balanceOf(state.vaultSpecs.ca).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(0);
            }
            catch {
                return 0
            }
        },
        async GetPriceChart_Token() {
            var _res = []
            try {
                await axios({
                    method: 'get',
                    url: 'https://api.arkfi.io/analytics.json',
                    responseType: 'json'
                })
                .then((response) => {
                    _res = response.data//.data
                })
                .catch(() => {
                    _res = []
                })
            } catch {
                _res = []
            }
            return _res
        },
        async GetARKBalance_Token({ state }, { web3, address }) {
            try {
                var _val = await state.contractBscToken.methods.balanceOf(address).call();
                _val = web3.utils.fromWei(_val);
                // arkBalance2 = Math.floor(Number(_val));
                // arkBalance = Math.floor(Number(_val) * 100) / 100;
                return Number(_val) // .toFixed(dec);
            }
            catch {
                return 0;
            }
        },
        async GetTotalSupply_Token({ state }, { web3 }) {
            try {
                var _val = await state.contractBscToken.methods.totalSupply().call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(0);
            }
            catch {
                return 0;
            }
        },
        async GetCirculatingSupply_Token({ state }, { web3 }) {
            try {
                var _val = await state.contractBscToken.methods.balanceOf(state.pairSpecs.ca).call();
                _val = web3.utils.fromWei(_val);
                return Number(_val) // .toFixed(0);
            }
            catch {
                return 0;
            }
        },
        async ApproveAllARK_Token({ state }, { address }) {
            var _res = null
            // the time-out catch
            const timeOut = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        show: true,
                        msg: "We didn't receive the transaction confirmation within a reasonable time. Please check your wallet if your request came through",
                        variant: 'warning'
                    })
                }, 25000)
            })
            // the user action
            const userAction = new Promise((resolve, reject) => {
                state.contractToken.methods.approveAllArk().send({
                    from: address
                }).on("receipt", () => {
                    resolve({
                        show: true,
                        msg: 'Your ARK has been approved',
                        variant: 'success'
                    })
                }).on("error", (error) => {
                    reject({
                        show: true,
                        msg: error.message,
                        variant: 'warning'
                    })
                })
            })
            // do the request
            await Promise.race([userAction, timeOut])
            .then((res) => {
                _res = res
            })
            .catch((err) => {
                _res = err
            })
            return _res
        },
        async ApproveARK_Swap({ state }, { web3, address }) {
            var _res = null
            // the time-out catch
            const timeOut = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        show: true,
                        msg: "We didn't receive the transaction confirmation within a reasonable time. Please check your wallet if your request came through",
                        variant: 'warning'
                    })
                }, 25000)
            })
            // the user action
            const userAction = new Promise((resolve, reject) => {
                state.contractToken.methods.approve(state.swapSpecs.ca, web3.utils.toWei("10000")).send({
                    from: address
                })
                .on('receipt', () => {
                    resolve({
                        show: true,
                        msg: 'Your ARK has been approved',
                        variant: 'success'
                    })
                })
                .on('error', (error) => {
                    reject({
                        show: true,
                        msg: error.message,
                        variant: 'warning'
                    })
                })
            })
            // do the request
            await Promise.race([userAction, timeOut])
            .then((res) => {
                _res = res
            })
            .catch((err) => {
                _res = err
            })
            return _res
        },
        async CheckApprovalARK_Swap({ state }, { web3, address }) {
            try {
                var allowance = await state.contractBscToken.methods.allowance(address, state.swapSpecs.ca).call();
                allowance = Number(web3.utils.fromWei(allowance));
                if (Number(allowance) >= 25) {
                    return true
                }
                else {
                    return false
                }
            }
            catch {
                // alert(error.message);
                return false
            }
        },
        async CheckApproval_Vault({ state }, { web3, address }) {
            try {
                var allowance = await state.contractBscToken.methods.allowance(address, state.vaultSpecs.ca).call();
                allowance = web3.utils.fromWei(allowance);

                if (Number(allowance) >= 4000) {
                    return true;
                }
                else {
                    return false;
                }
            }
            catch {
                // alert(error.message);
                return false;
            }
        }
    }
}