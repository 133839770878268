export const mixins = {
    methods: {
        async initHomeView(web3, address) {
            // fetch all home data
            var availRewards = await this.$store.dispatch("moduleWeb3Vault/GetAvailableRewards_Vault", { web3: web3, address: address })
            var bnbBalance = await this.$store.dispatch("moduleAccount/GetBNBBalance", { web3: web3, address: address })
            var busdBalance = await this.$store.dispatch("moduleWeb3Busd/getBUSDBalance", { web3: web3, address: address })
            var arkBalance = await this.$store.dispatch("moduleWeb3Token/GetARKBalance_Token", { web3: web3, address: address })
            var compareDate = await this.$store.dispatch("moduleWeb3Vault/LastAction_Vault", { address: address })
            var reserveBUSD = await this.$store.dispatch("moduleWeb3Pair/GetReserveBUSD_Pair", { web3: web3 })
            var reserveArk = await this.$store.dispatch("moduleWeb3Pair/GetReserveARK_Pair", { web3: web3 })
            var ilcReserves = await this.$store.dispatch("moduleWeb3Busd/GetILCReserves_Swap", { web3: web3 })
            var vaultReserves = await this.$store.dispatch("moduleWeb3Token/GetVaultReserves_Token", { web3: web3 })
            var arkPrice = await this.$store.dispatch("moduleWeb3Swap/GetArkPrice_Swap", { web3: web3 })
            var totalSupply = await this.$store.dispatch("moduleWeb3Token/GetTotalSupply_Token", { web3: web3 })
            var circulatingSupply = await this.$store.dispatch("moduleWeb3Token/GetCirculatingSupply_Token", { web3: web3 })
            var hasAccountVault = await this.$store.dispatch("moduleWeb3Vault/HasAccount_Vault", { address: address })
            var investors = await this.$store.dispatch("moduleWeb3Vault/GetInvestors_Vault")
            var paidUntil = await this.$store.dispatch("moduleWeb3Vault/GetAutoPaidUntil_Vault", { address: address })
            var priceChart = await this.$store.dispatch("moduleWeb3Token/GetPriceChart_Token")
            // set all home data
            this.$store.commit("moduleAccount/setBNBBalance", bnbBalance)
            this.$store.commit("moduleWeb3Busd/setBUSDBalance", busdBalance)
            this.$store.commit("moduleWeb3Token/setARKBalance_Token", arkBalance)
            this.$store.commit("moduleWeb3Vault/setTotalAccounts_Vault", investors)
            this.$store.commit("moduleWeb3Token/setTotalSupply_Token", totalSupply)
            this.$store.commit("moduleWeb3Token/setCirculatingSupply_Token", circulatingSupply)
            this.$store.commit("moduleAccount/setHasAccountVault", hasAccountVault)
            /*var distributionDate = new Date()
            distributionDate.setUTCHours(24, 0, 0, 0) // set the last legacy distribution data
            this.$store.commit("moduleLegacy/setLastDistribution", distributionDate)*/
            this.$store.commit("moduleVault/setVaultLastAction", compareDate)
            this.$store.dispatch("moduleVault/setRewardsTimer")
            // this.$store.dispatch("moduleLegacy/setRewardsTimer")
            this.$store.commit("moduleWeb3Pair/setReserveBUSD_Pair", reserveBUSD)
            this.$store.commit("moduleWeb3Pair/setReserveARK_Pair", reserveArk)
            this.$store.commit("moduleWeb3Busd/setILCReserves", ilcReserves)
            this.$store.commit("moduleWeb3Token/setVaultReserves", vaultReserves)
            this.$store.commit("moduleWeb3Swap/setArkPrice_Swap", arkPrice)
            this.$store.commit("moduleWeb3Vault/setPaidUntil", paidUntil)
            this.$store.commit("moduleWeb3Token/setPriceChartData", { val: priceChart, web3: web3 })
            this.$store.commit("moduleWeb3Token/setLiquidityChartData", { val: priceChart, web3: web3 })
            this.$store.commit("moduleWeb3Token/setMarketCapChartData", { val: priceChart, web3: web3 })
            this.$store.commit("moduleWeb3Token/setCirculatingSupplyChartData", { val: priceChart, web3: web3 })
            // fetch all legacy data
            var nftId = await this.$store.dispatch("moduleWeb3Legacy/GetNFTOfOwner_Legacy", { address: address })
            var nftLevel = await this.$store.dispatch("moduleWeb3Legacy/GetLevelNFT_Legacy", { nftId: nftId })
            var claimableRewards = await this.$store.dispatch("moduleWeb3Legacy/GetClaimableRewards_Legacy", { web3: web3, address: address })
            // set all legacy data
            this.$store.commit("moduleWeb3Legacy/setClaimableRewards", claimableRewards)
            this.$store.commit("moduleWeb3Legacy/setNftLevel", nftLevel)
            // fetch all vault data
            // var cwr = await this.$store.dispatch("moduleWeb3Vault/GetCWR_Vault", { address: address })
            // var maxCwr = await this.$store.dispatch("moduleWeb3Vault/GetMaxCWR_Vault", { address: address })
            var out = await this.$store.dispatch("moduleWeb3Vault/GetOut_Vault", { web3: web3, address: address })
            var principal = await this.$store.dispatch("moduleWeb3Vault/GetPrincipal_Vault", { web3: web3, address: address })
            if ((out + availRewards) >= 80000) {
                availRewards = (80000 - out)// .toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            // set all vault data
            this.$store.commit("moduleWeb3Vault/setAvailRewards", availRewards)
            this.$store.dispatch("moduleVault/setRewardsTimer")
            this.$store.commit("moduleWeb3Vault/setPrincipal", principal)
            // fetch all vault auto alloc data
            var profile = await this.$store.dispatch("moduleWeb3Vault/HasAutoAlloc_Vault", { address: address })
            // set all vault auto alloc data
            if (profile !== undefined && profile !== null) { this.$store.commit("moduleWeb3Vault/setAutoAllocProfile", profile[0]) }
            if (profile !== undefined && profile !== null) { this.$store.dispatch("moduleWeb3Vault/SetAutoAllocCWR_Vault", { withdrawVal: profile[0].withdrawpercent }) }
            // fetch all foundation data
            var bondPrice = await this.$store.dispatch("moduleWeb3Vault/CalculateUSDValueOfBond_Vault", { web3: web3, amount: "1" })
            var yourBonds = await this.$store.dispatch("moduleWeb3Foundation/GetShares_Bond", { web3: web3, address: address })
            var bondRewards = await this.$store.dispatch("moduleWeb3Foundation/GetAvailableRewards_Bond", { web3: web3, address: address })
            // set all foundation data
            this.$store.commit("moduleWeb3Foundation/setBondPrice", bondPrice)
            this.$store.commit("moduleWeb3Foundation/setYourBonds", yourBonds)
            this.$store.commit("moduleWeb3Foundation/setBondRewards", Number(bondRewards))
            // fetch all swap data
            var approvedSwapBUSD = await this.$store.dispatch("moduleWeb3Busd/CheckApprovalBUSD_Swap", { web3: web3, address: address, amount: 100 })
            var approvedSwapARK = await this.$store.dispatch("moduleWeb3Token/CheckApprovalARK_Swap", { web3: web3, address: address })
            // set all swap data
            this.$store.commit("moduleWeb3Busd/setBUSDApproved", approvedSwapBUSD)
            this.$store.commit("moduleWeb3Token/setARKApproved", approvedSwapARK)
            // twitter
            var twitterFeed = await this.$store.dispatch("moduleMain/GetTwitterFeed")
            this.$store.commit("moduleMain/setTwitterFeed", twitterFeed)
        },
        async initVaultView(web3, address) {
            // fetch all vault data
            var arkPrice = await this.$store.dispatch("moduleWeb3Swap/GetArkPrice_Swap", { web3: web3 })
            var compareDate = await this.$store.dispatch("moduleWeb3Vault/LastAction_Vault", { address: address })
            var cwr = await this.$store.dispatch("moduleWeb3Vault/GetCWR_Vault", { address: address })
            var maxCwr = await this.$store.dispatch("moduleWeb3Vault/GetMaxCWR_Vault", { address: address })
            var out = await this.$store.dispatch("moduleWeb3Vault/GetOut_Vault", { web3: web3, address: address })
            var availRewards = await this.$store.dispatch("moduleWeb3Vault/GetAvailableRewards_Vault", { web3: web3, address: address })
            var arkBalance = await this.$store.dispatch("moduleWeb3Token/GetARKBalance_Token", { web3: web3, address: address })
            var principal = await this.$store.dispatch("moduleWeb3Vault/GetPrincipal_Vault", { web3: web3, address: address })
            var deposits = await this.$store.dispatch("moduleWeb3Vault/GetDeposits_Vault", { web3: web3, address: address })
            var withdrawn = await this.$store.dispatch("moduleWeb3Vault/GetWithdrawn_Vault", { web3: web3, address: address })
            var airdropBalance = await this.$store.dispatch("moduleWeb3Vault/GetAirdropBalance_Vault", { web3: web3, address: address })
            var roi = await this.$store.dispatch("moduleWeb3Vault/GetROI_Vault", { address: address })
            var newDeposits = await this.$store.dispatch("moduleWeb3Vault/GetNewDeposits_Vault", { web3: web3, address: address })
            var compounds = await this.$store.dispatch("moduleWeb3Vault/GetCompounds_Vault", { web3: web3, address: address })
            var airdropped = await this.$store.dispatch("moduleWeb3Vault/GetAirdropped_Vault", { web3: web3, address: address })
            var hasAccountVault = await this.$store.dispatch("moduleWeb3Vault/HasAccount_Vault", { address: address })
            // var maxPayout = await this.$store.dispatch("moduleWeb3Vault/GetMaxPayout_Vault", { web3: web3, address: address })
            var maxPayout = principal * 3
            maxPayout = maxPayout > 80000 ? 80000 : maxPayout
            var ndv = await this.$store.dispatch("moduleWeb3Vault/GetNDVAmount_Vault", { web3: web3, address: address })
            var airdropReceived = await this.$store.dispatch("moduleWeb3Vault/GetAirdropsReceived_Vault", { web3: web3, address: address })
            var tax = await this.$store.dispatch("moduleWeb3Vault/GetTax_Vault", { address: address })
            tax = tax < 10 ? 10 : tax
            var referralRewards = await this.$store.dispatch("moduleWeb3Vault/GetReferalRewards_Vault", { web3: web3, address: address })
            if ((out + availRewards) >= 80000) {
                availRewards = (80000 - out)// .toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            var mpp = ((Number(out) + Number(availRewards)) / 80000 * 100);
            // set all vault data
            this.$store.commit("moduleWeb3Swap/setArkPrice_Swap", arkPrice)
            this.$store.commit("moduleAccount/setHasAccountVault", hasAccountVault)
            this.$store.commit("moduleVault/setVaultLastAction", compareDate)
            this.$store.dispatch("moduleVault/setRewardsTimer")
            this.$store.commit("moduleWeb3Vault/setCwr", cwr)
            this.$store.commit("moduleWeb3Vault/setMaxCwr", maxCwr)
            this.$store.commit("moduleWeb3Vault/setOut", out)
            this.$store.commit("moduleWeb3Vault/setAvailRewards", availRewards)
            this.$store.commit("moduleWeb3Token/setARKBalance_Token", arkBalance)
            this.$store.commit("moduleWeb3Vault/setPrincipal", principal)
            this.$store.commit("moduleWeb3Vault/setDeposits", deposits)
            this.$store.commit("moduleWeb3Vault/setWithdrawn", withdrawn)
            this.$store.commit("moduleWeb3Vault/setAirdropBalance", airdropBalance)
            this.$store.commit("moduleWeb3Vault/setRoi", roi)
            this.$store.commit("moduleWeb3Vault/setNewDeposits", newDeposits)
            this.$store.commit("moduleWeb3Vault/setCompounds", compounds)
            this.$store.commit("moduleWeb3Vault/setAirdropped", airdropped)
            this.$store.commit("moduleWeb3Vault/setMaxPayout", maxPayout)
            this.$store.commit("moduleWeb3Vault/setNdv", ndv)
            this.$store.commit("moduleWeb3Vault/setAirdropReceived", airdropReceived)
            this.$store.commit("moduleWeb3Vault/setTax", tax)
            this.$store.commit("moduleWeb3Vault/setReferralRewards", referralRewards)
            this.$store.commit("moduleWeb3Vault/setAvailableRewards", availRewards)
            this.$store.commit("moduleWeb3Vault/setMpp", mpp)
            this.$store.commit("moduleWeb3Vault/setPaidOut", out + availRewards)
            // fetch all vault auto alloc data
            var paidUntil = await this.$store.dispatch("moduleWeb3Vault/GetAutoPaidUntil_Vault", { address: address })
            var dayPrice = await this.$store.dispatch("moduleWeb3Vault/PricePerAction_Vault", { web3: web3 })
            var nftId = await this.$store.dispatch("moduleWeb3Legacy/GetNFTOfOwner_Legacy", { address: address })
            var nftLevel = await this.$store.dispatch("moduleWeb3Legacy/GetLevelNFT_Legacy", { nftId: nftId })
            var profile = await this.$store.dispatch("moduleWeb3Vault/HasAutoAlloc_Vault", { address: address })
            // set all vault auto alloc data
            this.$store.commit("moduleWeb3Vault/setPaidUntil", paidUntil)
            this.$store.commit("moduleWeb3Vault/setDayPrice", dayPrice)
            this.$store.commit("moduleWeb3Legacy/setNftLevel", nftLevel)
            if (profile !== undefined && profile !== null) { this.$store.commit("moduleWeb3Vault/setAutoAllocProfile", profile[0]) }
            if (profile !== undefined && profile !== null) { this.$store.dispatch("moduleWeb3Vault/SetAutoAllocCWR_Vault", { withdrawVal: profile[0].withdrawpercent }) }
            // fetch all vault deposit data
            var approved = await this.$store.dispatch("moduleWeb3Token/CheckApproval_Vault", { web3: web3, address: address })
            // set all vault deposit data
            this.$store.commit("moduleWeb3Token/setArkDepositApproved", approved)
        },
        async initSyndicateView(web3, address) {
            // fetch all syndicate data
            var airdropBalance = await this.$store.dispatch("moduleWeb3Vault/GetAirdropBalance_Vault", { web3: web3, address: address })
            var downline = await this.$store.dispatch("moduleWeb3Syndicate/CallAPI_Downline", { address: address })
            var upline = await this.$store.dispatch("moduleWeb3Syndicate/CallAPI_Upline", { address: address })
            // var directs = 0; //Get From API
            var airdropReceived = await this.$store.dispatch("moduleWeb3Vault/GetAirdropsReceived_Vault", { web3: web3, address: address })
            var airdropped = await this.$store.dispatch("moduleWeb3Vault/GetAirdropped_Vault", { web3: web3, address: address })
            var myReferree = await this.$store.dispatch("moduleWeb3Vault/GetReferrerOf_Vault", { address: address })
            // var syndicateMembers = 0; // Get From API
            // set all syndicate data
            this.$store.commit("moduleWeb3Vault/setAirdropBalance", airdropBalance)
            this.$store.commit("moduleWeb3Vault/setAirdropReceived", airdropReceived)
            this.$store.commit("moduleWeb3Vault/setAirdropped", airdropped)
            this.$store.commit("moduleWeb3Vault/setMyReferree", myReferree)
            this.$store.commit("moduleWeb3Syndicate/setDownline", downline)
            this.$store.commit("moduleWeb3Syndicate/setUpline", upline)
        },
        async initFoundationView(web3, address) {
            // fetch all foundation data
            var totalRewardsPool = await this.$store.dispatch("moduleWeb3Foundation/GetRewardsPool_Bond", { web3: web3 })
            var totalBondStaked = await this.$store.dispatch("moduleWeb3Foundation/GetTotalShares_Bond", { web3: web3 })
            var totalValBondStaked = await this.$store.dispatch("moduleWeb3Vault/CalculateUSDValueOfBond_Vault", { web3: web3, amount: totalBondStaked.toString() })
            var bondPrice = await this.$store.dispatch("moduleWeb3Vault/CalculateUSDValueOfBond_Vault", { web3: web3, amount: "1" })
            var arkPrice = await this.$store.dispatch("moduleWeb3Swap/GetArkPrice_Swap", { web3: web3 })
            var bondValue = await this.$store.dispatch("moduleWeb3Vault/GetBondValue_Vault", { web3: web3, address: address })
            var yourBonds = await this.$store.dispatch("moduleWeb3Foundation/GetShares_Bond", { web3: web3, address: address })
            var bondRewards = await this.$store.dispatch("moduleWeb3Foundation/GetAvailableRewards_Bond", { web3: web3, address: address })
            var reserveBUSD = await this.$store.dispatch("moduleWeb3Pair/GetReserveBUSD_Pair", { web3: web3 })
            var reserveArk = await this.$store.dispatch("moduleWeb3Pair/GetReserveARK_Pair", { web3: web3 })
            var totalSupplyPair = await this.$store.dispatch("moduleWeb3Pair/GetTotalSupply_Pair", { web3: web3 })
            // nft info is needed for referral rewards level
            var nftId = await this.$store.dispatch("moduleWeb3Legacy/GetNFTOfOwner_Legacy", { address: address })
            var nftLevel = await this.$store.dispatch("moduleWeb3Legacy/GetLevelNFT_Legacy", { nftId: nftId })
            var busdBalance = await this.$store.dispatch("moduleWeb3Busd/getBUSDBalance", { web3: web3, address: address })
            // set all foundation data
            this.$store.commit("moduleWeb3Foundation/setTotalRewardsPool", totalRewardsPool)
            this.$store.commit("moduleWeb3Foundation/setTotalBondStaked", totalBondStaked)
            this.$store.commit("moduleWeb3Foundation/setTotalValBondStaked", totalValBondStaked)
            this.$store.commit("moduleWeb3Foundation/setBondPrice", bondPrice)
            this.$store.commit("moduleWeb3Swap/setArkPrice_Swap", arkPrice)
            this.$store.commit("moduleWeb3Foundation/setBondValue", bondValue)
            this.$store.commit("moduleWeb3Foundation/setYourBonds", yourBonds)
            this.$store.commit("moduleWeb3Foundation/setBondRewards", Number(bondRewards))
            this.$store.commit("moduleWeb3Legacy/setNftLevel", nftLevel)
            this.$store.commit("moduleWeb3Busd/setBUSDBalance", busdBalance)
            this.$store.commit("moduleWeb3Pair/setReserveBUSD_Pair", reserveBUSD)
            this.$store.commit("moduleWeb3Pair/setReserveARK_Pair", reserveArk)
            this.$store.commit("moduleWeb3Pair/setTotalSupplyPair", totalSupplyPair)
            // fetch all swap data
            var approvedSwapBUSD = await this.$store.dispatch("moduleWeb3Busd/CheckApprovalBUSD_Swap", { web3: web3, address: address, amount: 100 })
            var approvedSwapARK = await this.$store.dispatch("moduleWeb3Token/CheckApprovalARK_Swap", { web3: web3, address: address })
            // set all swap data
            this.$store.commit("moduleWeb3Busd/setBUSDApproved", approvedSwapBUSD)
            this.$store.commit("moduleWeb3Token/setARKApproved", approvedSwapARK)
        },
        async initLotteryView(web3/*, address*/) {
            // fetch all lottery data
            var sparkDistributionDate = new Date()
            sparkDistributionDate.setUTCHours(20, 0, 0, 0) // set the last legacy distribution data
            var sparkWinners = await this.$store.dispatch("moduleWeb3Vault/GetSparkWinners_Vault")
            var sparkPayOut = await this.$store.dispatch("moduleWeb3Vault/GetSparkPayout_Vault", { web3: web3 })
            var sparkPrizePool = await this.$store.dispatch("moduleWeb3Vault/GetSparkPrizePool_Vault", { web3: web3 })
            // set all lottery data
            this.$store.commit("moduleMenu/setLastSparkDistribution", sparkDistributionDate)
            this.$store.commit("moduleMenu/setSparkProgressOrb")
            this.$store.commit("moduleWeb3Vault/setSparkWinners_Vault", sparkWinners)
            this.$store.commit("moduleWeb3Vault/setSparkPayOut_Vault", sparkPayOut)
            this.$store.commit("moduleWeb3Vault/setSparkPrizePool_Vault", sparkPrizePool)
        },
        async initPodcastsView() {
            // fetch all podcasts data
            await this.$store.dispatch("moduleMenu/GetPodcasts")
        },
        async initLegacyView(web3, address) {
            // fetch all legacy data
            var distributionDate = new Date()
            distributionDate.setUTCHours(24, 0, 0, 0) // set the last legacy distribution data
            var nftId = await this.$store.dispatch("moduleWeb3Legacy/GetNFTOfOwner_Legacy", { address: address })
            var mintedSilver = await this.$store.dispatch("moduleWeb3Legacy/GetTotalSupplyOfLevel_Legacy", { level: 1 })
            var mintedGold = await this.$store.dispatch("moduleWeb3Legacy/GetTotalSupplyOfLevel_Legacy", { level: 2 })
            var mintedPlatinum = await this.$store.dispatch("moduleWeb3Legacy/GetTotalSupplyOfLevel_Legacy", { level: 3 })
            var priceSilver = await this.$store.dispatch("moduleWeb3Legacy/GetPriceSilver_Legacy", { web3: web3 })
            var priceGold = await this.$store.dispatch("moduleWeb3Legacy/GetPriceGold_Legacy", { web3: web3 })
            var pricePlatinum = await this.$store.dispatch("moduleWeb3Legacy/GetPricePlatinum_Legacy", { web3: web3 })
            var totalNftSupply = await this.$store.dispatch("moduleWeb3Legacy/GetTotalSupply_Legacy")
            var rewardsPool = await this.$store.dispatch("moduleWeb3Legacy/GetRewardsPool_Legacy", { web3: web3 })
            var approvedLegacySilver = await this.$store.dispatch("moduleWeb3Busd/CheckApproval_Legacy", { web3: web3, address: address, amount: 1000 })
            var approvedLegacyGold = await this.$store.dispatch("moduleWeb3Busd/CheckApproval_Legacy", { web3: web3, address: address, amount: 4000 })
            var approvedLegacyPlatinum = await this.$store.dispatch("moduleWeb3Busd/CheckApproval_Legacy", { web3: web3, address: address, amount: 10000 })
            var shares = await this.$store.dispatch("moduleWeb3Legacy/GetShares_Legacy", { web3: web3, address: address })
            var totalShares = await this.$store.dispatch("moduleWeb3Legacy/GetTotalShares_Legacy", { web3: web3 })
            var claimableRewards = await this.$store.dispatch("moduleWeb3Legacy/GetClaimableRewards_Legacy", { web3: web3, address: address })
            var nftLocked = await this.$store.dispatch("moduleWeb3Legacy/IsLocked_Legacy", { id: nftId })
            var nftLevelAccount = await this.$store.dispatch("moduleWeb3Legacy/GetLevelOfAccount_Legacy", { address: address })
            var nftLevel = await this.$store.dispatch("moduleWeb3Legacy/GetLevelNFT_Legacy", { nftId: nftId })
            var priceLevelUp = await this.$store.dispatch("moduleWeb3Legacy/GetPriceForLevelUp_Legacy", { web3: web3, nftId: nftId })
            // set all legacy data
            this.$store.commit("moduleLegacy/setLastDistribution", distributionDate)
            this.$store.dispatch("moduleLegacy/setRewardsTimer")
            this.$store.commit("moduleLegacy/setNftMinted", {level: 1, minted: `${mintedSilver}/3000`})
            this.$store.commit("moduleLegacy/setNftMinted", {level: 2, minted: `${mintedGold}/500`})
            this.$store.commit("moduleLegacy/setNftMinted", {level: 3, minted: `${mintedPlatinum}/100`})
            this.$store.commit("moduleLegacy/setNftPrice", {level: 1, price: priceSilver})
            this.$store.commit("moduleLegacy/setNftPrice", {level: 2, price: priceGold})
            this.$store.commit("moduleLegacy/setNftPrice", {level: 3, price: pricePlatinum})
            this.$store.commit("moduleWeb3Legacy/setTotalNftSupply", totalNftSupply)
            this.$store.commit("moduleWeb3Legacy/setNftRewardsPool", rewardsPool)
            this.$store.commit("moduleWeb3Legacy/setApprovedLegacySilver", approvedLegacySilver)
            this.$store.commit("moduleWeb3Legacy/setApprovedLegacyGold", approvedLegacyGold)
            this.$store.commit("moduleWeb3Legacy/setApprovedLegacyPlatinum", approvedLegacyPlatinum)
            this.$store.commit("moduleWeb3Legacy/setShares", shares)
            this.$store.commit("moduleWeb3Legacy/setTotalShares", totalShares)
            this.$store.commit("moduleWeb3Legacy/setClaimableRewards", claimableRewards)
            this.$store.commit("moduleWeb3Legacy/setNftLocked", nftLocked)
            this.$store.commit("moduleWeb3Legacy/setNftLevelAccount", nftLevelAccount)
            this.$store.commit("moduleWeb3Legacy/setNftLevel", nftLevel)
            this.$store.commit("moduleWeb3Legacy/setPriceLevelUp", priceLevelUp)
        }
    }
}