export const moduleVault = {
    namespaced: true,
    state () {
        return {
            vaultLastAction: null,
            rewardsTimer: '00:00:00',
            vaultTimer: null,
            statsModalOpen: false,
            progressDone: 0,
            pullRefresherDisabled: false,
            depositReferrer: ''
        }
    },
    getters: {
        vaultLastAction(state) {
            return state.vaultLastAction
        },
        rewardsTimer(state) {
            return state.rewardsTimer
        },
        statsModalOpen(state) {
            return state.statsModalOpen
        },
        progressDone(state) {
            return state.progressDone
        },
        pullRefresherDisabled(state) {
            return state.pullRefresherDisabled
        },
        depositReferrer(state) {
            return state.depositReferrer
        }
    },
    mutations: {
        setDepositReferrer(state, val) {
            state.depositReferrer = val
        },
        setStatsModalOpen(state, val) {
            state.statsModalOpen = val
        },
        setVaultLastAction(state, val) {
            state.vaultLastAction = val
        },
        clearRewardsTimer(state) {
            clearInterval(state.vaultTimer);
        },
        getTimer(state) {
            if (state.vaultLastAction !== null) {
                var now = new Date()
                var dateTo = state.vaultLastAction.getTime() + (60 * 60 * 24 * 1000)
                var difference = dateTo - now.getTime();
                if (difference <= 0) {
                    state.rewardsTimer = '00:00:00'
                    clearInterval(state.vaultTimer);
                    return;
                } else {
                    var seconds = Math.floor(difference / 1000);
                    var minutes = Math.floor(seconds / 60);
                    var hours = Math.floor(minutes / 60);
                    hours %= 24;
                    minutes %= 60;
                    seconds %= 60;
                    var hourText = hours < 10 ? "0" + hours : hours
                    var minutesText = minutes < 10 ? "0" + minutes : minutes
                    var secondsText = seconds < 10 ? "0" + seconds : seconds
                    state.rewardsTimer = hourText + ":" + minutesText + ":" + secondsText
                }
            }
        },
        setProgressBar(state) {
            if (state.vaultLastAction !== null) {
                var now = new Date()
                var dateTo = state.vaultLastAction.getTime() + (60 * 60 * 24 * 1000) // total
                var percPerSecond = 100 / (dateTo - state.vaultLastAction.getTime())
                var difference = now.getTime() - dateTo
                var done = (percPerSecond * difference) + 100
                state.progressDone = done/100
                // console.log(done)
            }
        },
        setPullRefresherDisabled(state, val) {
            state.pullRefresherDisabled = val
        }
    },
    actions: {
        setRewardsTimer({ state, commit }) {
            commit('clearRewardsTimer')
            state.vaultTimer = setInterval(() => {
                commit('getTimer')
                commit('setProgressBar')
            }, 1000);
        }
    }
}