<template>
  <ion-app>
    <splash-screen :show-splash-screen="showSplashScreen"></splash-screen>
    <wallet-connect-modal></wallet-connect-modal>
    <install-modal></install-modal>
    <scanner-modal></scanner-modal>
    <home-qr-modal></home-qr-modal>
    <ion-router-outlet id="main-content"/>
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue'
import { defineComponent, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Device } from '@capacitor/device'
import SplashScreen from '@/components/main/SplashScreen.vue'
import InstallModal from '@/components/main/install/InstallModal.vue'
import WalletConnectModal from '@/components/main/walletconnect/WalletConnectModal.vue'
import ScannerModal from '@/components/main/scanner/ScannerModal.vue'
import HomeQrModal from '@/components/suite/home/qr/HomeQrModal.vue'
export default defineComponent({
  name: 'App',
  setup() {
    const pwaInstalled = window.matchMedia('(display-mode: standalone)').matches ? true : false
    const showSplashScreen = ref(pwaInstalled)
    const router = useRouter()
    const route = useRoute()
    return {
      showSplashScreen,
      router,
      route
    }
  },
  beforeCreate() {
    window.addEventListener('beforeinstallprompt', function(e) {
      e.preventDefault();
      return false;
    });
  },
  created() {
    this.initOnAppStart()
  },
  methods: {
    async initOnAppStart() {
      let pwaInstalled = this.checkPwaInstalled()
      let deviceInfo = Device.getInfo()
      Promise.all([
        deviceInfo
      ])
      .then((data) => {
        this.$store.commit("moduleMain/setDeviceInfo", data[0])
        setTimeout(() => {
          this.showSplashScreen = false
          if (data[0].operatingSystem === 'ios' || data[0].operatingSystem === 'android') {
            if (pwaInstalled === false) {
              this.$store.commit('moduleMain/triggerInstallModal', true)
            }
          } else {
            this.router.replace('/web');
          }
        }, pwaInstalled ? 1500 : 0);
      })
      .catch(() => {
        this.showSplashScreen = false
        this.router.replace('/web');
      });
    },
    checkPwaInstalled() {
      const pwaInstalled = window.matchMedia('(display-mode: standalone)').matches ? true : false;
      return pwaInstalled
    }
  },
  components: {
    IonApp,
    IonRouterOutlet,
    SplashScreen,
    InstallModal,
    WalletConnectModal,
    ScannerModal,
    HomeQrModal
  }
});
</script>
<style scoped>

</style>