<template>
    <!-- <ion-modal :is-open="!walletConnected" :presenting-element="presentingElement"> -->
    <ion-modal :is-open="!walletConnected && (deviceInfo.operatingSystem === 'ios' || deviceInfo.operatingSystem === 'android')" :handle="false" :can-dismiss="true">
        <wallet-connect-modal-page-base>
            <div class="ion-text-center splash-screen">
                <div class="splash-container ion-text-center">
                    <home-rainbow style="margin-top: 175px"></home-rainbow>
                    <div class="splash-vertical-center ion-text-center" style="margin-top: 100px">
                        <div v-if="!showInvalidChainAlert">
                            <ion-text color="light">
                                <div style="font-size: 16px; font-weight: 400;">In order to use the ArkFi app, you need to connect your wallet</div>
                            </ion-text>
                        </div>
                        <ion-text color="warning" v-if="showInvalidChainAlert">
                            <h5>ARK is currently only supported for the BNB Chain.</h5>
                        </ion-text>
                    </div>
                </div>
            </div>
            <ion-footer class="ion-no-border hub-review-button-container">
                <ion-toolbar color="white">
                    <ion-grid class="ion-text-center">
                        <ion-row>
                            <ion-col>
                                <ion-progress-bar v-if="showProgressBar" color="medium" type="indeterminate"></ion-progress-bar>
                                <ion-button shape="round" color="medium" v-if="!showProgressBar" expand="block" @click="wc()">Connect Wallet</ion-button>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-toolbar>
            </ion-footer>
        </wallet-connect-modal-page-base>
    </ion-modal>
</template>
<script>
import { 
    IonModal, IonGrid, IonRow, IonCol, IonText, IonButton, IonFooter, IonToolbar, IonProgressBar
} from '@ionic/vue';
import {
    defineComponent, ref
} from 'vue';
import { useRouter } from 'vue-router'
import WalletConnectModalPageBase from './WalletConnectModalPageBase.vue'
import HomeRainbow from '@/components/suite/home/HomeRainbow.vue'
import { EthereumProvider } from '@walletconnect/ethereum-provider'
/*import { UniversalProvider}  from "@walletconnect/universal-provider"
import { SignClient } from "@walletconnect/sign-client"
import { Web3Modal } from "@web3modal/standalone"
import { ethers } from "ethers"*/
let WalletConnectProvider = null
let provider = null
// let provider2 = null
import Web3 from "web3"
export default defineComponent({
    name: 'WalletConnectModal',
    setup() {
        const pwaInstalled = window.matchMedia('(display-mode: standalone)').matches ? true : false
        const showProgressBar = ref(false)
        const showInvalidChainAlert = ref(false)
        const router = useRouter()
        return {
            pwaInstalled,
            showProgressBar,
            showInvalidChainAlert,
            router
        }
    },
    async created() {
        if (this.pwaInstalled) {
         await this.wc()
        }
    },
    methods: {
        async wc() {
            if (window.location.href.indexOf("mobile") > -1) {
                await this.connectWallet()
            } else {
                await this.connectWalletV1()
            }
        },
        async connectWallet() {
            try {
                this.showProgressBar = true
                var chainId = null
                provider = await EthereumProvider.init({
                    projectId: '0e1b758cf6089c0b7f3d2ac8d4a0db66',
                    chains: [56],
                    rpcMap: {56:"https://bsc-dataseed1.binance.org/"},
                    showQrModal: true,
                    qrModalOptions: {
                        themeMode: 'dark',
                        themeVariables: {
                            "--w3m-font-family": "Montserrat, sans-serif"
                        }
                    }
                })
                const acc = await provider.enable()
                const web3 = new Web3(provider)
                const web3bsc = new Web3(new Web3.providers.HttpProvider("https://bsc-dataseed1.binance.org/"));
                chainId = await web3.eth.getChainId()
                if (chainId === 56) {
                    provider.on('accountsChanged', () =>{ 
                        console.log('test')
                    })
                    this.$store.commit("moduleWeb3Main/setWeb3", web3)
                    this.$store.commit("moduleWeb3Main/setProvider", provider)
                    // this.$store.commit("moduleWeb3Main/modifyWallets", { action: 'add', val: wallet })
                    await this.initWeb3Contracts(web3, web3bsc)
                    this.$store.commit("moduleMain/setWalletConnected", true)
                    this.$store.commit("moduleMain/setWalletConnected", true)
                    this.$store.commit("moduleAccount/setAddress", acc[0])
                    this.router.replace('/suite/home')
                    this.showProgressBar = false
                } else {
                    await provider.disconnect()
                    this.$store.commit("moduleMain/setWalletConnected", false)
                    this.showInvalidChainAlert = true
                    setTimeout(() => {
                        this.showInvalidChainAlert = false
                        this.showProgressBar = false
                    }, 2500)
                }
            } catch {
                await provider.disconnect()
                provider = null
                this.$store.commit("moduleMain/setWalletConnected", false)
                this.showProgressBar = false
            }
        },
        async connectWalletV1() {
            try {
                this.showProgressBar = true
                WalletConnectProvider = window.WalletConnectProvider.default;
                provider = new WalletConnectProvider({
                    rpc: { 56: "https://bsc-dataseed1.binance.org/" },
                    qrcodeModalOptions: {
                        desktopLinks: []
                    }
                })
                const acc = await provider.enable()
                console.log(provider)
                const web3 = new Web3(provider)
                const web3bsc = new Web3(new Web3.providers.HttpProvider("https://bsc-dataseed1.binance.org/"));
                var chainId = await web3.eth.getChainId()
                if (chainId === 56) {
                    this.$store.commit("moduleWeb3Main/setWeb3", web3)
                    this.$store.commit("moduleWeb3Main/setProvider", provider)
                    // this.$store.commit("moduleWeb3Main/modifyWallets", { action: 'add', val: wallet })
                    await this.initWeb3Contracts(web3, web3bsc)
                    this.$store.commit("moduleMain/setWalletConnected", true)
                    this.$store.commit("moduleAccount/setAddress", acc[0])
                    this.router.replace('/suite/home')
                    this.showProgressBar = false
                } else {
                    await provider.disconnect()
                    WalletConnectProvider = null
                    provider = null
                    this.$store.commit("moduleMain/setWalletConnected", false)
                    this.showInvalidChainAlert = true
                    setTimeout(() => {
                        this.showInvalidChainAlert = false
                        this.showProgressBar = false
                    }, 2500)
                }
            } catch {
                await provider.disconnect()
                WalletConnectProvider = null
                provider = null
                this.$store.commit("moduleMain/setWalletConnected", false)
                this.showProgressBar = false
            }
        },
        async initWeb3Contracts(web3, web3bsc) {
            // busd
            await this.$store.commit('moduleWeb3Busd/Init_Contracts', { web3: web3, web3bsc: web3bsc })
            // foundation
            await this.$store.commit('moduleWeb3Foundation/Init_Contracts', { web3: web3, web3bsc: web3bsc })
            // legacy
            await this.$store.commit('moduleWeb3Legacy/Init_Contracts', { web3: web3, web3bsc: web3bsc })
            // vault
            await this.$store.commit('moduleWeb3Vault/Init_Contracts', { web3: web3, web3bsc: web3bsc })
            // pair
            await this.$store.commit('moduleWeb3Pair/Init_Contracts', { web3: web3, web3bsc: web3bsc })
            // swap
            await this.$store.commit('moduleWeb3Swap/Init_Contracts', { web3: web3, web3bsc: web3bsc })
            // token
            await this.$store.commit('moduleWeb3Token/Init_Contracts', { web3: web3, web3bsc: web3bsc })
        }
    },
    components: {
        IonModal,
        WalletConnectModalPageBase,
        IonGrid, 
        IonRow, 
        IonCol, 
        IonText,
        IonFooter,
        IonButton,
        IonToolbar,
        IonProgressBar,
        HomeRainbow
    },
    computed: {
        wallets: {
            get() {
                return this.$store.getters['moduleWeb3Main/wallets']
            }
        },
        walletConnected: {
            get() {
                return this.$store.getters['moduleMain/walletConnected']
            }
        },
        deviceInfo: {
            get() {
                return this.$store.getters['moduleMain/deviceInfo']
            }
        },
        disconnectWallet: {
            get() {
                return this.$store.getters["moduleMain/disconnectWallet"]
            }
        },
        height: {
            get() {
                return window.height
            }
        }
    },
    watch: {
        async disconnectWallet(newVal) {
            if (newVal) {
                await provider.disconnect()
                this.$router.go()
            }
        }
    }
})
</script>
<style scoped>
    .hub-review-button-container {
        bottom: 0;
        position: absolute;
        background: var(--ion-color-secondary);
    }

    .intro-img {
        align-items: center;
    }

    .splash-screen {
        width: 100%;
        height: 90%;
    }

    .splash-hidden {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 0.5s, opacity 0.5s linear;
    }

    .splash-container {
        height: 100%;
        width: 100%;
        position: relative;
    }

    .splash-vertical-center {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    ion-button {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
    }
</style>