<template>
    <ion-page>
      <ion-content :fullscreen="false">
          <slot />
      </ion-content>
    </ion-page>
</template>

<script>
  import { 
      defineComponent 
  } from 'vue'
  import {
      IonPage, IonContent
  } from '@ionic/vue'
  import {
      closeOutline
  } from 'ionicons/icons';
  export default defineComponent({
        name: 'WalletConnectModalPageBase',
        setup() {
            return {
                closeOutline
            }
        },
        components: {
            IonPage,
            IonContent
        }
    })
</script>

<style scoped>

</style>
