import axios from 'axios'
export const moduleWeb3Syndicate = {
    namespaced: true,
    state () {
        return {
            downline: {},
            downlineArr: [],
            maxDepth: 1,
            depth: 0,
            upline: [],
            sTest: 0
        }
    },
    getters: {
        downline(state) {
            return state.downline
        },
        upline(state) {
            return state.upline
        }
    },
    mutations: {
        setDownline(state, val) {
            state.downline = val
        },
        setUpline(state, val) {
            state.upline = val
        }
    },
    actions: {
        async CallAPI_Downline({ state }, { address }) {
            state.sTest = 1
            var _res = {}
            try {
                await axios({
                    method: 'post',
                    url: 'https://api.arkfi.io/downline',
                    responseType: 'json',
                    data: `{"investor": "${address}"}`,
                })
                .then((response) => {
                    _res = response.data.data
                })
                .catch(() => {
                    _res = {}
                })
            } 
            catch {
                _res = {}
            }
            return _res
        },
        async CallAPI_Upline({ state }, { address }) {
            state.sTest = 1
            var _res = {}
            try {
                await axios({
                    method: 'post',
                    url: 'https://api.arkfi.io/upline',
                    responseType: 'json',
                    data: `{"investor": "${address}"}`,
                })
                .then((response) => {
                    _res = response.data.data
                })
                .catch(() => {
                    _res = {}
                })
            } catch {
                _res = {}
            }
            return _res
        }
    }
}