<template>
    <ion-page>
      <ion-content :fullscreen="false" class="dark-background">
          <slot />
          <div style="min-height: 100px"></div>
      </ion-content>
    </ion-page>
  </template>
  
  <script>
  import { 
      defineComponent 
  } from 'vue'
  import {
      IonPage, IonContent
  } from '@ionic/vue'
  export default defineComponent({
        name: 'QrModalPageBase',
        components: {
            IonPage,
            IonContent
        }
    })
  </script>
  
  <style scoped>
  .dark-background {
    --background: linear-gradient(180deg, #1C1C3C 0%, #03071C 100%);
    backdrop-filter: blur(5.5px);
  }
  </style>
  