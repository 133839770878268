let token = {
    // contract
    ca: "0x111120a4cFacF4C78e0D6729274fD5A5AE2B1111",
    // ABI
    abi: [
        { "inputs": [], "stateMutability": "nonpayable", "type": "constructor" },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": true, "internalType": "address", "name": "owner", "type": "address" },
                { "indexed": true, "internalType": "address", "name": "spender", "type": "address" },
                { "indexed": false, "internalType": "uint256", "name": "value", "type": "uint256" }
            ],
            "name": "Approval",
            "type": "event"
        },
        { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "address", "name": "arkSwapAddress", "type": "address" }], "name": "ArkSwapAddressChanged", "type": "event" },
        { "anonymous": false, "inputs": [], "name": "BnbRescued", "type": "event" },
        { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "address", "name": "limitedAddress", "type": "address" }], "name": "NewLimitedAddress", "type": "event" },
        { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "address", "name": "limitlessAddress", "type": "address" }], "name": "NewLimitlessAddress", "type": "event" },
        { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "bool", "name": "active", "type": "bool" }], "name": "SwapStatusSet", "type": "event" },
        { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "uint256", "name": "howMuchWasMissing", "type": "uint256" }], "name": "SwapToppedUp", "type": "event" },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "address", "name": "wallet", "type": "address" },
                { "indexed": false, "internalType": "bool", "name": "status", "type": "bool" }
            ],
            "name": "TaxStatusOfWalletSet",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "uint256", "name": "liq", "type": "uint256" },
                { "indexed": false, "internalType": "uint256", "name": "vault", "type": "uint256" }
            ],
            "name": "TaxesChanged",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "address", "name": "tokenRescued", "type": "address" },
                { "indexed": false, "internalType": "uint256", "name": "amountRescued", "type": "uint256" }
            ],
            "name": "TokensSentToTreasury",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": true, "internalType": "address", "name": "from", "type": "address" },
                { "indexed": true, "internalType": "address", "name": "to", "type": "address" },
                { "indexed": false, "internalType": "uint256", "name": "value", "type": "uint256" }
            ],
            "name": "Transfer",
            "type": "event"
        },
        { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "address", "name": "treasury", "type": "address" }], "name": "TreasuryAddressChanged", "type": "event" },
        { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "address", "name": "vaultAddress", "type": "address" }], "name": "VaultAdded", "type": "event" },
        { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "address", "name": "vault", "type": "address" }], "name": "VaultAddressChanged", "type": "event" },
        { "anonymous": false, "inputs": [{ "indexed": false, "internalType": "address", "name": "vaultAddress", "type": "address" }], "name": "VaultRemoved", "type": "event" },
        {
            "anonymous": false,
            "inputs": [
                { "indexed": false, "internalType": "address", "name": "vaultAddress", "type": "address" },
                { "indexed": false, "internalType": "uint256", "name": "howMuchWasMissing", "type": "uint256" }
            ],
            "name": "VaultToppedUp",
            "type": "event"
        },
        { "inputs": [], "name": "CEO", "outputs": [{ "internalType": "address", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "ROUTER", "outputs": [{ "internalType": "contract IDEXRouter", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "vaultAddress", "type": "address" }], "name": "addVault", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        {
            "inputs": [
                { "internalType": "address", "name": "holder", "type": "address" },
                { "internalType": "address", "name": "spender", "type": "address" }
            ],
            "name": "allowance",
            "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "address", "name": "spender", "type": "address" },
                { "internalType": "uint256", "name": "amount", "type": "uint256" }
            ],
            "name": "approve",
            "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        { "inputs": [], "name": "approveAllArk", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "spender", "type": "address" }], "name": "approveMax", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "account", "type": "address" }], "name": "balanceOf", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "decimals", "outputs": [{ "internalType": "uint8", "name": "", "type": "uint8" }], "stateMutability": "pure", "type": "function" },
        {
            "inputs": [
                { "internalType": "address", "name": "spender", "type": "address" },
                { "internalType": "uint256", "name": "subtractedValue", "type": "uint256" }
            ],
            "name": "decreaseAllowance",
            "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "address", "name": "spender", "type": "address" },
                { "internalType": "uint256", "name": "addedValue", "type": "uint256" }
            ],
            "name": "increaseAllowance",
            "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "isVault", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "limitless", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "liqTax", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "addressThatShouldBeLimited", "type": "address" }], "name": "makeAddressLimited", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "addressThatShouldBeLimitless", "type": "address" }], "name": "makeAddressLimitless", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [], "name": "name", "outputs": [{ "internalType": "string", "name": "", "type": "string" }], "stateMutability": "pure", "type": "function" },
        { "inputs": [], "name": "pair", "outputs": [{ "internalType": "address", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "vaultAddress", "type": "address" }], "name": "removeVault", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [], "name": "rescueBnb", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "tokenToRescue", "type": "address" }], "name": "sendTokenToTreasury", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "arkSwapAddress", "type": "address" }], "name": "setSwap", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        {
            "inputs": [
                { "internalType": "uint256", "name": "newLiqTax", "type": "uint256" },
                { "internalType": "uint256", "name": "newVaultTax", "type": "uint256" }
            ],
            "name": "setTax",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "address", "name": "wallet", "type": "address" },
                { "internalType": "bool", "name": "status", "type": "bool" }
            ],
            "name": "setTaxStatusOfWallet",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        { "inputs": [{ "internalType": "address", "name": "treasuryAddress", "type": "address" }], "name": "setTreasury", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "vaultAddress", "type": "address" }], "name": "setVault", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [], "name": "swap", "outputs": [{ "internalType": "address", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "swapActive", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "symbol", "outputs": [{ "internalType": "string", "name": "", "type": "string" }], "stateMutability": "pure", "type": "function" },
        { "inputs": [{ "internalType": "address", "name": "", "type": "address" }], "name": "taxFreeWallet", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "view", "type": "function" },
        { "inputs": [{ "internalType": "bool", "name": "active", "type": "bool" }], "name": "toggleSwap", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
        { "inputs": [], "name": "totalSupply", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
        {
            "inputs": [
                { "internalType": "address", "name": "recipient", "type": "address" },
                { "internalType": "uint256", "name": "amount", "type": "uint256" }
            ],
            "name": "transfer",
            "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                { "internalType": "address", "name": "sender", "type": "address" },
                { "internalType": "address", "name": "recipient", "type": "address" },
                { "internalType": "uint256", "name": "amount", "type": "uint256" }
            ],
            "name": "transferFrom",
            "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        { "inputs": [], "name": "treasury", "outputs": [{ "internalType": "address", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "vault", "outputs": [{ "internalType": "address", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" },
        { "inputs": [], "name": "vaultTax", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" }
    ]           
}
export { token }
