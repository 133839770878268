<template>
    <ion-modal :is-open="qrModalOpen" :initial-breakpoint="0.9" :breakpoints="[0, 0.25, 0.4, 0.5, 0.75, 0.9]" :handle="true" :show-backdrop="false" :can-dismiss="true" @didDismiss="dismissQrModal()" style="--border-radius: 27px">
        <home-qr-modal-page-base>
            <ion-grid>
                <ion-row>
                    <ion-col class="ion-text-left">
                        <div style="font-size: 20px; color: white; font-weight: 600;" class="ion-margin-top">QR Code</div>
                    </ion-col>
                    <ion-col class="ion-text-right">
                        <ion-button fill="clear" @click="dismissQrModal()" color="light" style="margin-top: 10px">
                            <ion-icon slot="start" :icon="closeOutline"></ion-icon>
                            Close
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <ion-grid style="margin-top: 50px">
                <ion-row class="ion-margin-top">
                    <ion-col class="ion-text-center">
                        <!-- <vue-qrious id="my-personal-qr" :value="`https://${host}/connect/${address}`" :size="225" foreground="#6a64ff"/> -->
                        <div :style="`background: white; width: 230px; height: 230px; padding: 5px; text-align: center; margin: 0 auto !important;`">
                        <!-- <div :style="`background: white; width: 230px; height: 230px; padding: 5px; text-align: center; margin-left: ${(width-255)/2}px`"> -->
                            <QRCodeVue3
                                :width="225"
                                :height="225"
                                :value="`https://${host}/connect/${address}`"
                                :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'Q' }"
                                :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 4 }"
                                image="assets/images/home/ark_qr_logo_gradient.png"
                                :dotsOptions="qrDotsOptions"
                                :dotsOptionsHelper="{ colorType: { single: true, gradient: false }, gradient: { linear: true, radial: false, color1: '#6a1a4c', color2: '#6a1a4c', rotation: 0 } }"
                                :backgroundOptions="{ color: '#ffffff' }"
                                cornersSquareOptions
                                :cornersSquareOptionsHelper="{ colorType: { single: true, gradient: false }, gradient: { linear: true, radial: false, color1: '#000000', color2: '#000000', rotation: '0' } }"
                                cornersDotOptions
                                fileExt="png"
                                :download="false"
                                id="my-personal-qr"
                            />
                        </div>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-margin-top">
                    <ion-col class="ion-text-center">
                        <ion-button color="medium" style="width: 225px" shape="round" @click="shareQR()">Share QR <ion-icon slot="end" :icon="shareSocialOutline"></ion-icon></ion-button>
                    </ion-col>
                </ion-row>
                <ion-row class="ion-margin-top">
                    <ion-col class="ion-text-center">
                        <div style="color: rgba(255, 255, 255, 0.6); font-size: 16px; font-weight: 400">
                            This is your personal QR referral code. Let your friends and family scan this code or share your QR on your socials. Adding friends and family will unlock new abilities and higher return potential.
                        </div>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <!--
            <ion-grid style="margin-top: 50px">
                <ion-row>
                    <ion-col class="ion-text-center">
                        <div style="color: rgba(255, 255, 255, 0.6); font-size: 16px; font-weight: 400">
                            This is your personal QR referral code. Let your friends and family scan this code. Adding friends and family will unlock new abilities and higher return potential.
                        </div>
                    </ion-col>
                </ion-row>
            </ion-grid>
            -->
        </home-qr-modal-page-base>
    </ion-modal>
</template>
<script>
    import { 
        IonModal, IonGrid, IonRow, IonCol, IonIcon, IonButton, alertController
    } from '@ionic/vue';
    import {
        defineComponent, ref
    } from 'vue';
    import {
        closeOutline, shareSocialOutline
    } from 'ionicons/icons';
    import HomeQrModalPageBase from '@/components/suite/home/qr/HomeQrModalPageBase.vue'
    // import VueQrious from 'vue-qrious'
    import QRCodeVue3 from "qrcode-vue3"
    import html2canvas from 'html2canvas'
    export default defineComponent({
        name: 'QrModal',
        setup() {
            const showProgressBar = ref(false)
            return {
                showProgressBar,
                closeOutline,
                shareSocialOutline
            }
        },
        data() {
            return {
                qrDotsOptions: {
                    type: 'square',
                    color: '#6a1a4c',
                    gradient: {
                        type: 'linear',
                        rotation: 0,
                        colorStops: [
                            { offset: 0, color: '#295bfc' },
                            { offset: 1, color: '#35364f' },
                        ]
                    }
                }
            }
        },
        methods: {
            dismissQrModal() {
                this.$store.commit('moduleHome/setQrModalOpen', false)
            },
            async presentAlert(title, btn) {
                const alert = await alertController.create({
                    header: 'Info',
                    subHeader: title,
                    buttons: btn,
                    backdropDismiss: false,
                    cssClass: 'custom-alert'
                })
                await alert.present()
                /*
                const { role } = await alert.onDidDismiss()
                this.proceedAction = role
                */
            },
            async shareQR() {
                var _status = 'fail'
                // var alertTitle = ''
                // var alertBtn = ''
                const canvasElement = await html2canvas(document.getElementById('my-personal-qr'), {
                    scale: 3, 
                })
                const dataUrl = canvasElement.toDataURL();
                const blob = await (await fetch(dataUrl)).blob();
                const filesArray = [
                    new File(
                        [blob],
                        'qr.jpg',
                        {
                            type: blob.type,
                            lastModified: new Date().getTime()
                        }
                    )
                ]
                // post content
                const shareData = {
                    text: `Join the ARK. Scan my QR code or follow my personal referral link (via Chrome or Safari): https://${this.host}/connect/${this.address}`,
                    files: filesArray,
                }
                // sharing routine
                if (navigator.canShare && navigator.canShare(shareData)) {
                    await navigator.share(shareData)
                    .then(() => {
                        _status = 'success'
                    })
                    .catch(() => {
                        _status = 'fail'
                    });
                } else {
                    _status = 'fail'
                }
                console.log(_status)
                /*
                if (_status === 'success') {
                    alertTitle = "You've shared this winner on your socials."
                    alertBtn = [{ text: 'OK', role: 'cancel', cssClass: 'alert-button-custom' }]
                    await this.presentAlert(alertTitle, alertBtn) // showAlert = true
                } else {
                    alertTitle = "Something went worng sharing on your socials."
                    alertBtn = [{ text: 'OK', role: 'cancel', cssClass: 'alert-button-custom' }]
                    await this.presentAlert(alertTitle, alertBtn) // showAlert = true
                }
                */
            }
        },
        components: {
            IonModal,
            IonGrid,
            IonRow,
            IonCol,
            IonIcon,
            IonButton,
            HomeQrModalPageBase,
            // VueQrious,
            QRCodeVue3
        },
        computed: {
            web3: {
                get() {
                    return this.$store.getters['moduleWeb3Main/web3']
                }
            },
            address: {
                get() {
                    return this.$store.getters['moduleAccount/address']
                }
            },
            qrModalOpen: {
                get() {
                    return this.$store.getters['moduleHome/qrModalOpen']
                }
            },
            host: {
                get() {
                    return window.location.hostname
                }
            },
            width: {
                get() {
                   return window.innerWidth
                }
            }
        }
    })
</script>
<style scoped>
    ion-button {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
    }
    .qr-class {
        padding: 15px;
        margin: 15px;
    }
</style>